import React, { useRef, useCallback } from "react";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";

import LayoutEdit from "../../components/Layouts/Edit";
import Form from "./components/Form";

import Request, { HTTP_STATUS } from "../../services/request";
import {
  listUsersRoute,
  showUserRoute,
  createUserRoute,
} from "../../routes/config";
import { usersRouteApi } from "../../routes/config/api";
import getValidationsErrors from "../../utils/errors/getValidationsErrors";
import { useGlobalLoading } from "../../hooks/GlobalLoadingContext";

export const Edit: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { id } = useParams<any>();
  const { loading, setLoading } = useGlobalLoading();

  const updateUser = useCallback(
    async (data: any) => {
      try {
        setLoading(true);

        const formattedData = {
          name: data.name,
          username: data.username,
          email: data.email,
          powerbi_user: data.powerbi_user,
          is_admin: data.is_admin,
          user_profiles: data.profiles,
        };

        if (data.password && data.password_confirmation) {
          Object.assign(formattedData, {
            new_password: data.password,
          });
        }

        const response = await Request.put(
          `${usersRouteApi.path}/${id}`,
          formattedData
        );

        toast.success("Usuário atualizado com sucesso!");
        history.push(showUserRoute.build({ id: response.id }));
      } catch (err) {
        const statusCode = err.response?.data?.statusCode;
        if (statusCode === HTTP_STATUS.UNPROCESSABLE_ENTITY) {
          const errMessage = err.response.data.message;

          if (errMessage?.username) {
            formRef.current?.setErrors(errMessage);
          }
        } else {
          toast.error("Ops, não foi possível atualizar este usuário!");
        }
      } finally {
        setLoading(false);
      }
    },
    [history, id, setLoading]
  );

  const handleUpdate = useCallback(async () => {
    try {
      formRef.current?.setErrors({});

      const data = formRef.current?.getData() as any;

      Object.assign(data, {
        email: data.email || '',
        profiles: data.profiles?.map((profile: any) => profile.value) || null,
      });

      const validatePassword =
        !!data.password || !!data.password_confirmation
          ? {
              password: Yup.string()
                .required("Senha deve ter no mínimo 6 digítos")
                .min(6, "No mínimo 6 digitos"),
              password_confirmation: Yup.string()
                .required("Senha deve ter no mínimo 6 digítos")
                .min(6, "No mínimo 6 digitos")
                .oneOf(
                  [Yup.ref("password"), ""],
                  "Senha de confirmação incorreta"
                ),
            }
          : {};

      const schema = Yup.object().shape({
        name: Yup.string().required("Campo Obrigatório"),
        username: Yup.string()
          .required("Campo Obrigatório")
          .min(5, "No mínimo 5 caracteres"),
        email: Yup.string().email("E-mail inválido"),
        powerbi_user: Yup.string().required("Campo Obrigatório"),
        profiles: Yup.mixed().required('Campo obrigatório'),
        ...validatePassword,
      });

      await schema.validate(data, { abortEarly: false });

      updateUser(data);
    } catch (err) {
      const validationErrors = getValidationsErrors(err);
      formRef.current?.setErrors(validationErrors);
    }
  }, [updateUser]);

  return (
    <LayoutEdit
      breadcrumbs={[
        { text: "Usuários", path: listUsersRoute.path },
        { text: "Editar Usuário" },
      ]}
      navMenuProps={{
        newRoute: createUserRoute.path,
      }}
      footerActionsProps={{
        onSubmit: handleUpdate,
        onCancelRoute: listUsersRoute.path,
        loading,
      }}
    >
      <Form formRef={formRef} />
    </LayoutEdit>
  );
};
