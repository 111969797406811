import React, { useMemo, useCallback } from "react";
import { AiOutlineStar, AiFillStar } from 'react-icons/ai';

import Grid from '../../components/Col';

import { useProfileModules } from '../../hooks/ProfileModulesContext';
import { dashboardRoute } from '../../routes/config';
import dynamicSort from '../../utils/dynamicSort';

import noImageUrl from '../../assets/images/no-image.png';
import bannerImg from '../../assets/images/banner.png'

import { Container, Link } from "./styles";

interface ILinkItem {
  name: string;
  route: string;
  image_url: string;
  is_favorite: string;
  showFavorites: boolean;
  onClick(): void;
  onClickFavorite(): void;
}

const NoData: React.FC = () => (
  <div className="no-data">
    <span>Ops, nenhum dashboard encontrado!</span>
  </div>
);

const LinkItem: React.FC<ILinkItem> = ({
  name,
  route,
  image_url,
  is_favorite,
  onClick,
  onClickFavorite,
  showFavorites
}) => (
  <Grid className="link-item" item xl={2} lg={2} md={2} sm={4}>
    {showFavorites && (
      <button className="favorite-btn" onClick={onClickFavorite}>
        {is_favorite
          ? <AiFillStar />
          : <AiOutlineStar />
        }
      </button>
    )}
    <Link target='_blank' to={route} onClick={onClick}>
      <div className="image">
        <img src={image_url || noImageUrl} alt="Imagem do módulo" />
      </div>
      <div className="description">
        <span>{name?.toUpperCase()}</span>
      </div>
    </Link>
  </Grid>
)

const Dashboard: React.FC = () => {
  const {
    modules: profileModules,
    lastAccesses,
    setLastAccess,
    isFavorites,
    showingFilteredModules,
    updateModule
  } = useProfileModules();
  const modules: any[] = useMemo(
    () => profileModules
      .filter((module) => module.route !== dashboardRoute.path)
      .sort(dynamicSort('name', 'ASC')),
    [profileModules]
  );
  const showLastAccessess = useMemo(() => {
    return !isFavorites && !showingFilteredModules && !!lastAccesses.length;
  }, [isFavorites, lastAccesses.length, showingFilteredModules])

  const handleSetFavorite = useCallback((moduleId: number) => {
    updateModule(moduleId);
  }, [updateModule]);

  return (
    <Container>
      <img className="banner" src={bannerImg} alt="Banner da dashboard" />

      <div className="content">
        <h3 className="title-available-dashs">Dashboards Disponíveis</h3>
        <Grid container className="available-dashs" spacing={2}>
          {!modules.length && <NoData />}

          {modules.map((module) => (
            <LinkItem
              showFavorites
              key={module.id}
              name={module.name}
              route={module.route}
              image_url={module.image_url}
              is_favorite={module.is_favorite}
              onClick={() => setLastAccess(module)}
              onClickFavorite={() => handleSetFavorite(module.id)}
            />
          ))}
        </Grid>

        {showLastAccessess && (
          <>
            <h3 className="title-last-accesses">Últimos Acessos</h3>
            <Grid container className="last-accesses" spacing={2}>
              {lastAccesses.map((module: any) => (
                <LinkItem
                  key={module.id}
                  name={module.name}
                  route={module.route}
                  image_url={module.image_url}
                  is_favorite={module.is_favorite}
                  showFavorites={false}
                  onClick={() => {}}
                  onClickFavorite={() => {}}
                />
              ))}
            </Grid>
          </>
        )}
      </div>
    </Container>
  );
};

export default Dashboard;
