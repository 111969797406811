import React from "react";
import { format } from "date-fns";

import LayoutList from "../../components/Layouts/List";
import ExportToXML from "../../components/ExportToXML";

import { usersRouteApi } from "../../routes/config/api";
import {
  createUserRoute,
  showUserRoute,
  editUserRoute,
} from "../../routes/config";

import { Admin } from "./styles";

const List: React.FC = () => {
  return (
    <LayoutList
      breadcrumbs={[{ text: "Usuários" }]}
      navMenuProps={{
        StartComponent: () => (
          <ExportToXML
            filename="usuarios.csv"
            fetchUrl={`${usersRouteApi.path}?per_page=1000000&sort=id&direction=asc`}
            formatData={(data) => {
              const formattedData = data.data?.map((row: any) => {
                const userProfiles = row.user_profiles
                  ?.map((profile: any) => profile.name)
                  ?.join(', ');

                return ({
                  name: row.name,
                  username: row.username,
                  powerbi_user: row.powerbi_user,
                  profile: `"${userProfiles || ''}"`,
                  created_at: format(new Date(row.created_at), 'dd/MM/yyyy'),
                  updated_at: format(new Date(row.updated_at), 'dd/MM/yyyy'),
                });
              });

              return formattedData;
            }}
            headers={[
              'NOME',
              'USUÁRIO',
              'POWER BI',
              'PERFIL',
              'DATA CRIAÇÃO',
              'DATA ULT. ALTERAÇÃO',
            ]}
            columns={[
              'name',
              'username',
              'powerbi_user',
              'profile',
              'created_at',
              'updated_at',
            ]}
          />
        ),
        newRoute: createUserRoute.path,
      }}
      tableProps={{
        requestOptions: {
          url: usersRouteApi,
        },
        columns: [
          { name: "name", title: "Nome" },
          { name: "username", title: "Usuário" },
          { name: "powerbi_user", title: "Power BI" },
          { name: "profile", title: "Perfil" },
          { name: "is_admin", title: " " },
          { name: "actions", title: "Ações" },
        ],
        columnsProperties: [
          { columnName: "username", width: 280 },
          { columnName: "powerbi_user", width: 200 },
          { columnName: "profile", width: 250 },
          { columnName: "is_admin", width: 135, align: "center" },
          { columnName: "actions", width: 150, align: "center" },
        ],
        customActions: ({ id }) => ({
          actions: ["show", "edit", "delete"],
          paths: {
            edit: editUserRoute.build({ id }),
            show: showUserRoute.build({ id }),
            delete: `${usersRouteApi.path}/${id}`,
          },
        }),
        dataTypeProvider: [
          {
            columnName: "profile",
            formatterComponent: ({ row }) => (
              <div style={{ fontSize: "0.75rem", whiteSpace: "break-spaces" }}>
                {row.user_profiles?.map((profile: any) => profile.name).join(", ")}
              </div>
            ),
          },
          {
            columnName: "is_admin",
            formatterComponent: ({ row }) => (
              <Admin isAdmin={row.is_admin ? "true" : "false"}>
                {row.is_admin ? "Administrador" : "Usuário"}
              </Admin>
            ),
          },
        ],
      }}
    />
  );
};

export { List };
