import styled from "styled-components";

export const IconButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 33px;
  height: 33px;
  border-radius: 50%;
  background-color: transparent;
  transition: background-color 150ms ease-in-out;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }
`;
