import styled, { keyframes, css } from "styled-components";

import InputGroupComponent from "../InputGroup";

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(90px);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

const animation = css`
  animation: ${appearFromLeft} 900ms;
`;

export const InputGroup = styled(InputGroupComponent)`
  position: relative;
  opacity: ${(props): number => (props?.loading === "true" ? 0 : 1)};
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 3px;
  padding: 60px 24px 24px 24px;

  -webkit-box-shadow: 0px 3px 15px 0px #70707033 !important;
  -moz-box-shadow: 0px 3px 15px 0px #70707033 !important;
  box-shadow: 0px 3px 15px 0px #70707033 !important;

  ${(props): any => props?.loading === "false" && animation};
`;

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -23px;
  left: 30px;
  width: 57px;
  height: 47px;
  border-radius: 10px 1px 10px 0;
  background-color: ${(props) => props.theme.colors.primary};
  -webkit-box-shadow: 0px 3px 15px 0px #70707029 !important;
  -moz-box-shadow: 0px 3px 15px 0px #70707029 !important;
  box-shadow: 0px 3px 15px 0px #70707029 !important;
  opacity: 0.9;
`;
