import React, { useState, useEffect, useRef, useCallback } from "react";
import { useField } from "@unform/core";

import Col from "../../Col";
import { MetricsProps } from "../Base";
import { theme } from "../../../styles/theme";

import { Checkbox as MUICheckbox } from "./styles";

interface CheckboxProps extends MetricsProps {
  name: string;
  label: string;
  onChange?: (checked: boolean) => void;
}

const Checkbox: React.FC<CheckboxProps> = ({
  name,
  label,
  onChange,
  xs = 12,
  sm = 12,
  md = 12,
}) => {
  const { fieldName, defaultValue, registerField } = useField(name);
  const [state, setState] = useState(!!defaultValue);
  const inputRef = useRef<any>(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => {
        return state;
      },
      setValue: (_, newValue) => {
        setState(!!newValue);
      },
    });
  }, [fieldName, registerField, state, setState]);

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>): void => {
      setState(event.target.checked);
      onChange && onChange(event.target.checked);
    },
    [onChange]
  );

  return (
    <Col style={{ padding: 0 }} xs={xs} sm={sm} md={md}>
      <MUICheckbox
        color="default"
        style={
          state
            ? {
                color: theme.light.colors.primary,
                opacity: 1,
              }
            : {
                color: "#bbb",
                opacity: 0.9,
              }
        }
        inputRef={inputRef}
        size="small"
        checked={state}
        onChange={handleChange}
        inputProps={{ name }}
      />
      <span style={{ fontSize: "0.7rem", color: "#aaa" }}>{label}</span>
    </Col>
  );
};

export default Checkbox;
