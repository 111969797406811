import React, { useRef } from "react";
import { FormHandles } from "@unform/core";
import { useParams } from "react-router-dom";

import LayoutShow from "../../components/Layouts/Show";
import Form from "./components/Form";

import {
  createUserRoute,
  editUserRoute,
  listUsersRoute,
} from "../../routes/config";

export const Show: React.FC = () => {
  const { id } = useParams();
  const formRef = useRef<FormHandles>(null);

  return (
    <LayoutShow
      breadcrumbs={[
        { text: "Usuários", path: listUsersRoute.path },
        { text: "Visualizar Usuário" },
      ]}
      navMenuProps={{
        newRoute: createUserRoute.path,
        editRoute: editUserRoute.build({ id }),
      }}
    >
      <Form formRef={formRef} isShowPage />
    </LayoutShow>
  );
};
