import React, { useState, useCallback } from "react";
import Toolbar from "@material-ui/core/Toolbar";
import { Link } from "react-router-dom";
import Avatar from "@material-ui/core/Avatar";
import Popover from "@material-ui/core/Popover";
import Divider from '@material-ui/core/Divider';
import { FiLogIn, FiUsers } from "react-icons/fi";
import { BiTask } from "react-icons/bi";
import { HiOutlineDocumentReport } from "react-icons/hi";
import { RiAccountCircleLine, RiListSettingsLine } from "react-icons/ri";
import { BiSupport, BiHelpCircle } from "react-icons/bi";

import { useAuth } from "../../hooks/AuthContext";
import { useProfileModules } from "../../hooks/ProfileModulesContext";
import {
  dashboardRoute,
  updateProfileRoute,
  listModulesRoute,
  listProfilesRoute,
  listUsersRoute,
  listTasksRoute,
  helpRoute,
} from "../../routes/config";
import biLogo from '../../assets/images/bi-logo.png';

import InputSearch from './InputSearch';
import SupportModal from './SupportModal';

import {
  Container,
  Root,
  AppBar,
  AvatarContainer,
  PopoverContent
} from "./styles";

const Header: React.FC = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null);
  const [isOpenSupportModal, setIsOpenSupportModal] = useState(false);
  const { signOut, user } = useAuth();
  const { removeLastAccesses } = useProfileModules();

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLAnchorElement>): void => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    },
    [setAnchorEl]
  );

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleSignOut = useCallback(() => {
    removeLastAccesses();
    signOut();
  }, [removeLastAccesses, signOut]);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Container>
      <Root>
        <AppBar position="fixed">
          <Toolbar>
            <Link to={dashboardRoute.path} className="logo">
              <img className="bi-logo" src={biLogo} alt="Logo do BI" />
              <span>Portal BI</span>
            </Link>

            <div className="search-container">
              <InputSearch />
            </div>

            <AvatarContainer popoverOpen={open ? 1 : 0}>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <a aria-describedby={id} onClick={handleClick}>
                <Avatar style={{ backgroundColor: "#F49639", color: "#fff" }}>
                  {user.name.slice(0, 1).toUpperCase()}
                </Avatar>
              </a>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
                style={{
                  zIndex: 3000,
                  marginTop: 10,
                }}
              >
                <PopoverContent>
                  <Link to={updateProfileRoute.path} onClick={handleClose}>
                    <RiAccountCircleLine size={19} />
                    <span>Minha conta</span>
                  </Link>

                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a onClick={() => {
                    handleClose();
                    setIsOpenSupportModal(true);
                  }}>
                    <BiSupport />
                    <span>Fale Conosco/Suporte</span>
                  </a>

                  <Link to={helpRoute.path} onClick={handleClose}>
                    <BiHelpCircle size={20} />
                    <span>Ajuda</span>
                  </Link>

                  <Divider />

                  {!!user.is_admin && (
                    <>
                      <Link to={listModulesRoute.path} onClick={handleClose}>
                        <HiOutlineDocumentReport size={19} />
                        <span>Módulos</span>
                      </Link>
                      <Link to={listProfilesRoute.path} onClick={handleClose}>
                        <RiListSettingsLine size={18} />
                        <span>Perfis</span>
                      </Link>
                      <Link to={listUsersRoute.path} onClick={handleClose}>
                        <FiUsers size={18} />
                        <span>Usuários</span>
                      </Link>
                      <Link to={listTasksRoute.path} onClick={handleClose}>
                        <BiTask size={19} />
                        <span>Tarefas</span>
                      </Link>
                      <Divider />
                    </>
                  )}

                  {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                  <a onClick={handleSignOut}>
                    <FiLogIn />
                    <span>Sair</span>
                  </a>
                </PopoverContent>
              </Popover>
            </AvatarContainer>

            <h3 className="schumann-logo">Schumann</h3>
          </Toolbar>
        </AppBar>
      </Root>
      <SupportModal
        isOpen={isOpenSupportModal}
        closeModal={() => setIsOpenSupportModal(false)}
      />
    </Container>
  );
}

export default Header;
