import React from "react";
import { format } from "date-fns";

import LayoutList from "../../components/Layouts/List";
import ExportToXML from "../../components/ExportToXML";

import { profilesRouteApi } from "../../routes/config/api";
import {
  createProfileRoute,
  showProfileRoute,
  editProfileRoute,
} from "../../routes/config";

const List: React.FC = () => {
  return (
    <LayoutList
      breadcrumbs={[{ text: "Perfis" }]}
      navMenuProps={{
        StartComponent: () => (
          <ExportToXML
            filename="perfis.csv"
            fetchUrl={`${profilesRouteApi.path}?per_page=1000000&sort=id&direction=asc`}
            formatData={(data) => {
              const formattedData = data.data?.map((row: any) => ({
                ...row,
                created_at: format(new Date(row.created_at), 'dd/MM/yyyy'),
                updated_at: format(new Date(row.updated_at), 'dd/MM/yyyy'),
              }));

              return formattedData;
            }}
            headers={[
              'CÓDIGO',
              'NOME',
              'DATA CRIAÇÃO',
              'DATA ULT. ALTERAÇÃO',
            ]}
            columns={[
              'id',
              'name',
              'created_at',
              'updated_at',
            ]}
          />
        ),
        newRoute: createProfileRoute.path,
      }}
      tableProps={{
        requestOptions: {
          url: profilesRouteApi,
        },
        columns: [
          { name: "id", title: "Código" },
          { name: "name", title: "Nome" },
          { name: "actions", title: "Ações" },
        ],
        columnsProperties: [
          { columnName: "id", width: 170 },
          { columnName: "actions", width: 150, align: "center" },
        ],
        customActions: ({ id }) => ({
          actions: ["show", "edit", "delete"],
          paths: {
            edit: editProfileRoute.build({ id }),
            show: showProfileRoute.build({ id }),
            delete: `${profilesRouteApi.path}/${id}`,
          },
        }),
        dataTypeProvider: [
          {
            columnName: "id",
            formatterComponent: ({ row }) => (
              <div style={{ fontSize: "0.75rem" }}>
                {String(row.id).padStart(6, "0")}
              </div>
            ),
          },
        ],
      }}
    />
  );
};

export { List };
