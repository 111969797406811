import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 55px;
  position: fixed;
  z-index: 1000;
  bottom: 0;
  right: 0;
  border: none;
  background-color: ${(props) => props.theme.colors.white};
  justify-content: flex-end;
  align-items: center;
  padding-right: 30px;
  -webkit-box-shadow: 2px 4px 12px 1px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 2px 4px 12px 1px rgba(0, 0, 0, 0.25);
  box-shadow: 2px 4px 12px 1px rgba(0, 0, 0, 0.25);
`;
