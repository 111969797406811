import React from "react";

import LayoutBase from "../Base";
import { NavMenuProps } from "../../NavMenu";
import FooterActions, { FooterActionsProps } from "../../FooterActions";

import { BreadcrumbProps } from "../../../hooks/BreadcrumbsContext";

interface LayoutShowProps {
  breadcrumbs: BreadcrumbProps[];
  navMenuProps: NavMenuProps;
  footerActionsProps: FooterActionsProps;
}

const Edit: React.FC<LayoutShowProps> = ({
  breadcrumbs,
  navMenuProps,
  children,
  footerActionsProps,
}) => {
  return (
    <LayoutBase breadcrumbs={breadcrumbs} navMenuProps={navMenuProps}>
      <div style={{ height: 30 }} />

      {children}

      <FooterActions {...footerActionsProps} />
      <div style={{ height: 55 }} />
    </LayoutBase>
  );
};

export default Edit;
