import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { FormHandles } from "@unform/core";
import { toast } from "react-toastify";
import { parseISO, format } from 'date-fns';

import Form from "../../../../components/Form";
import InputText from "../../../../components/Input/Text";
import InputSelect from "../../../../components/Input/Select";

import Request from "../../../../services/request";
import {
  usersRouteApi,
  modulesRouteApi,
  taskSchedulerRouteApi
} from "../../../../routes/config/api";
import { daysOptions } from '../../utils/daysOptions';

interface FormProps {
  isShowPage?: boolean;
  formRef: React.RefObject<FormHandles>;
  onSubmit?: (data: any) => void;
}

const FormComponent: React.FC<FormProps> = ({
  formRef,
  isShowPage = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [modules, setModules] = useState([]);
  const { id } = useParams<any>();

  const getHelperText = useCallback(() => {
    return isShowPage ? "" : "Campo Obrigatório";
  }, [isShowPage]);

  useEffect(() => {
    async function findUser(): Promise<void> {
      const usersData = await Request.get(`${usersRouteApi.path}?per_page=100000`);
      if (!!usersData.data) {
        const usersWithValidEmail = usersData.data
          .filter((userData: any) => !!userData.email)
          .map((userData: any) => ({
            label: userData.name,
            value: userData.id,
          }));

        setUsers(usersWithValidEmail as any);
      }

      const modulesData = await Request.get(`${modulesRouteApi.path}?per_page=100000`);
      if (!!modulesData.data) {
        const validModules = modulesData.data
          .filter((moduleData: any) => !moduleData.is_menu)
          .map((moduleData: any) => ({
            label: moduleData.name,
            value: moduleData.id,
          }));

        setModules(validModules as any);
      }

      if (id) {
        try {
          setLoading(true);

          const taskData = await Request.get(`${taskSchedulerRouteApi.path}/${id}`);

          if (!!taskData) {
            const startDate = format(parseISO(taskData.start_date), 'dd/MM/yyy');
            const endDate = format(parseISO(taskData.end_date), 'dd/MM/yyy');

            formRef.current?.setData({
              name: taskData.name,
              start_date: startDate,
              end_date: endDate,
              hour: taskData.hour,
              days: taskData.days?.split(',').map((day: any) => {
                const formattedDay = daysOptions.find(
                  (dayOption) => dayOption.value === day
                );

                return ({
                  label: formattedDay?.label || 'Desconhecido',
                  value: day,
                });
              }),
              module: {
                label: taskData.module?.name,
                value: taskData.module_id
              },
              users: taskData.user_ids.split(',').map((userId: string) => {
                const userInfo = usersData.data?.find(
                  (userData: any) => userData.id === userId
                );

                return ({
                  label: userInfo?.name || 'Desconhecido',
                  value: userId
                });
              })
            });
          }
        } catch (err) {
          toast.error("Ops, não foi possível carregar as informações");
        } finally {
          setLoading(false);
        }
      }
    }

    findUser();
  }, [formRef, id]);

  return (
    <Form formRef={formRef} loading={loading} disabledForm={isShowPage}>
      <InputText
        name="name"
        label="Nome"
        helperText={getHelperText()}
        autoComplete="off"
      />
      <InputText
        name="start_date"
        label="Data de Início"
        helperText={getHelperText()}
        autoComplete="off"
        inputMask={[/[0-3]/, /\d/, '/', /[0-1]/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        sm={6}
        md={6}
      />
      <InputText
        name="end_date"
        label="Data Final"
        helperText={getHelperText()}
        autoComplete="off"
        inputMask={[/[0-3]/, /\d/, '/', /[0-1]/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]}
        sm={6}
        md={6}
      />
      <InputText
        name="hour"
        label="Horário"
        helperText={getHelperText()}
        autoComplete="off"
        inputMask={[/[0-2]/, /\d/, ':', /[0-5]/, /\d/]}
        sm={6}
        md={6}
      />
      <InputSelect
        isMulti
        name="days"
        label="Selecione os dias"
        helperText={getHelperText()}
        isSearchable={false}
        closeMenuOnSelect={false}
        options={daysOptions as any}
        sm={6}
        md={6}
      />
      <InputSelect
        name="module"
        label="Selecione um módulo"
        helperText={getHelperText()}
        options={modules}
      />
      <InputSelect
        isMulti
        name="users"
        label="Selecione os usuários"
        helperText={getHelperText()}
        closeMenuOnSelect={false}
        options={users}
      />
    </Form>
  );
};

export default FormComponent;
