import React, { useRef, useCallback } from "react";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import LayoutCreate from "../../components/Layouts/Create";
import Form from "./components/Form";

import Request from "../../services/request";
import { listUsersRoute, showUserRoute } from "../../routes/config";
import { usersRouteApi } from "../../routes/config/api";
import getValidationsErrors from "../../utils/errors/getValidationsErrors";
import { useGlobalLoading } from "../../hooks/GlobalLoadingContext";

export const Create: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { loading, setLoading } = useGlobalLoading();

  const createUser = useCallback(
    async (data: any) => {
      try {
        setLoading(true);

        const response = await Request.post(usersRouteApi.path, {
          ...data,
          user_profiles: data.profiles,
        });

        toast.success("Usuário criado com sucesso!");
        history.push(showUserRoute.build({ id: response.id }));
      } catch (err) {
        toast.error("Ops, não foi possível cadastrar este usuário!");
      } finally {
        setLoading(false);
      }
    },
    [history, setLoading]
  );

  const handleCreate = useCallback(async () => {
    try {
      formRef.current?.setErrors({});

      const data = formRef.current?.getData() as any;

      Object.assign(data, {
        profiles: data.profiles?.map((profile: any) => profile.value) || null,
      });

      const schema = Yup.object().shape({
        name: Yup.string().required("Campo Obrigatório"),
        username: Yup.string()
          .required("Campo Obrigatório")
          .min(5, "No mínimo 5 caracteres"),
        email: Yup.string().email("E-mail inválido"),
        powerbi_user: Yup.string().required("Campo Obrigatório"),
        profiles: Yup.mixed().required('Campo obrigatório'),
        password: Yup.string()
          .required("Senha deve ter no mínimo 6 digítos")
          .min(6, "No mínimo 6 digitos"),
        password_confirmation: Yup.string()
          .required("Senha deve ter no mínimo 6 digítos")
          .min(6, "No mínimo 6 digitos")
          .oneOf([Yup.ref("password"), ""], "Senha de confirmação incorreta"),
      });

      await schema.validate(data, { abortEarly: false });

      createUser(data);
    } catch (err) {
      const validationErrors = getValidationsErrors(err);
      formRef.current?.setErrors(validationErrors);
    }
  }, [createUser]);

  return (
    <LayoutCreate
      breadcrumbs={[
        { text: "Usuários", path: listUsersRoute.path },
        { text: "Adicionar Usuário" },
      ]}
      footerActionsProps={{
        onSubmit: handleCreate,
        onCancelRoute: listUsersRoute.path,
        loading,
      }}
    >
      <Form formRef={formRef} />
    </LayoutCreate>
  );
};
