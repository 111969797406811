import React from "react";

import BaseLayout from "./components/BaseLayout";

import { helpRoute } from "../../routes/config";

import searchReportsImg from '../../assets/images/search-reports-1.png';

import { Container } from "./styles";

const SearchReports: React.FC = () => {
  return (
    <BaseLayout
      breadcrumbs={[
        { text: "Ajuda", path: helpRoute.path },
        { text: "Como Pesquisar Dashboards" },
      ]}
    >
      <Container>
        <div className="search-reports">
          <img src={searchReportsImg} alt="" />
          <span>
            1º - Na caixa de pesquisa, é possível realizar a busca pelo dashboard informando parte do ID ou do nome conforme exemplo acima.
          </span>
          <span>
            2º - Para fechar sua pesquisa, basta clicar no ícone (x) dentro do próprio campo de digitação.
          </span>
        </div>
      </Container>
    </BaseLayout>
  );
};

export { SearchReports };
