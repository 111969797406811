import React, { useRef, useCallback } from "react";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";

import LayoutEdit from "../../components/Layouts/Edit";
import Form from "./components/Form";

import Request from "../../services/request";
import {
  listProfilesRoute,
  showProfileRoute,
  createProfileRoute,
} from "../../routes/config";
import { profilesRouteApi } from "../../routes/config/api";
import getValidationsErrors from "../../utils/errors/getValidationsErrors";
import { useGlobalLoading } from "../../hooks/GlobalLoadingContext";

export const Edit: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { id } = useParams<any>();
  const { loading, setLoading } = useGlobalLoading();

  const updateProfile = useCallback(
    async (data: any) => {
      try {
        setLoading(true);

        const formattedProfileModules = data.modules?.map((module: any) => ({
          module_id: module.value,
        }));
        const response = await Request.put(`${profilesRouteApi.path}/${id}`, {
          name: data.name,
          profile_modules: formattedProfileModules,
        });

        toast.success("Perfil atualizado com sucesso!");
        history.push(showProfileRoute.build({ id: response.id }));
      } catch (err) {
        toast.error("Ops, não foi possível atualizar este perfil!");
      } finally {
        setLoading(false);
      }
    },
    [history, id, setLoading]
  );

  const handleUpdate = useCallback(async () => {
    try {
      formRef.current?.setErrors({});

      const data = formRef.current?.getData();
      const schema = Yup.object().shape({
        name: Yup.string().required("Campo Obrigatório"),
      });

      await schema.validate(data, { abortEarly: false });

      updateProfile(data);
    } catch (err) {
      const validationErrors = getValidationsErrors(err);
      formRef.current?.setErrors(validationErrors);
    }
  }, [updateProfile]);

  return (
    <LayoutEdit
      breadcrumbs={[
        { text: "Perfis", path: listProfilesRoute.path },
        { text: "Editar Perfil" },
      ]}
      navMenuProps={{
        newRoute: createProfileRoute.path,
      }}
      footerActionsProps={{
        onSubmit: handleUpdate,
        onCancelRoute: listProfilesRoute.path,
        loading,
      }}
    >
      <Form formRef={formRef} />
    </LayoutEdit>
  );
};
