import React from "react";
import {
  FormHandles,
  FormProps as UNFormProps,
  SubmitHandler,
} from "@unform/core";
import { Form } from "@unform/web";
import { MdReceipt } from "react-icons/md";

import Spinner from "../Spinner";

import { InputGroup, IconContainer } from "./styles";

interface FormProps extends Omit<UNFormProps, "onSubmit"> {
  onSubmit?: SubmitHandler;
  loading?: boolean;
  disabledForm?: boolean;
  formRef: React.RefObject<FormHandles>;
}

const FormComponent: React.FC<FormProps> = ({
  formRef,
  loading,
  onSubmit,
  disabledForm = false,
  children,
  ...restProps
}) => {
  return (
    <Form
      style={{ pointerEvents: disabledForm ? "none" : "auto", marginTop: 35 }}
      onSubmit={onSubmit || ((): void => {})}
      {...restProps}
      ref={formRef}
    >
      {loading && <Spinner loadingText="" />}
      <InputGroup loading={loading ? "true" : "false"}>
        <IconContainer>
          <MdReceipt size={24} color="#fff" />
        </IconContainer>

        {children}
      </InputGroup>
    </Form>
  );
};

export default FormComponent;
