const weekDays = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat'];

const humanWeekDays = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'];

export function formatWeekDays(days: string) {
  const selectedDays = days.split(',');
  const formattedDays = selectedDays.map((selectedDay) => {
    const weekDayIdx = weekDays.findIndex(
      (weekDay) => weekDay === selectedDay
    );

    return humanWeekDays[weekDayIdx];
  });

  return formattedDays.join(', ');
}
