import React from "react";

import BaseLayout from "./components/BaseLayout";

import { helpRoute } from "../../routes/config";

import saveFavoriteReportImg from '../../assets/images/save-favorite-report-1.png';

import { Container } from "./styles";

const SaveFavoriteReport: React.FC = () => {
  return (
    <BaseLayout
      breadcrumbs={[
        { text: "Ajuda", path: helpRoute.path },
        { text: "Como Salvar Dashboards nos Favoritos" },
      ]}
    >
      <Container>
        <div className="save-favorite-report">
          <img src={saveFavoriteReportImg} alt="" />
          <span>Se você possui um dashboard que utiliza com mais frequência, você pode clicar para favoritá-lo, assim otimiza seu tempo na pesquisa pelo mesmo dentro do Portal.</span>
          <span>
            1º - Para favoritar, clique na estrela no canto inferior direito do dashboard escolhido.
          </span>
          <span>
            2º - Após selecioná-lo como favorito, sempre que acessar o Portal novamente bastar clicar no <strong>Favoritos</strong> representado por uma estrela junto ao campo de pesquisa.
          </span>
          <span>
            3º - Para sair da visão dos favoritos, basta clicar novamente na estrela para retornar a visão de todos os dashs disponíveis no seu perfil.
          </span>
        </div>
      </Container>
    </BaseLayout>
  );
};

export { SaveFavoriteReport };
