import React, { useEffect } from "react";

import Table, { TableProps } from "../../Table";
import { NavMenuProps } from "../../NavMenu";
import LayoutBase from "../Base";

import { BreadcrumbProps } from "../../../hooks/BreadcrumbsContext";
import { QueryParamsProvider, useQueryParams } from "../../../hooks/QueryParamsContext";

interface LayoutListProps {
  breadcrumbs: BreadcrumbProps[];
  navMenuProps: NavMenuProps;
  tableProps: TableProps;
}

const Content: React.FC<LayoutListProps> = ({
  breadcrumbs,
  navMenuProps,
  tableProps,
  children,
}) => {
  const { setParams } = useQueryParams();

  useEffect(() => {
    setParams({
      page: 0,
      per_page: 5,
      sort: "id",
      direction: "desc",
      total: 0,
      searchValue: '',
    });
  }, [setParams]);

  return (
    <LayoutBase breadcrumbs={breadcrumbs} navMenuProps={navMenuProps}>
      <Table {...tableProps} />

      {children}
    </LayoutBase>
  );
}

const List: React.FC<LayoutListProps> = ({
  breadcrumbs,
  navMenuProps,
  tableProps,
  children,
}) => (
  <QueryParamsProvider>
    <Content
      breadcrumbs={breadcrumbs}
      navMenuProps={navMenuProps}
      tableProps={tableProps}
    >
      {children}
    </Content>
  </QueryParamsProvider>
);

export default List;
