import styled, { css } from "styled-components";
import { shade } from "polished";

const disableCSS = css`
  background-color: rgba(0, 0, 0, 0.12);
  color: rgba(0, 0, 0, 0.26);
  cursor: auto;
`;

const hoverCSS = css`
  &:hover {
    background-color: ${(props) => shade(0.15, props.theme.colors.primary)};
  }
`;

export const Container = styled.button`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  border: none;
  height: 44px;
  cursor: pointer;
  color: ${(props) => props.theme.colors.white};
  margin-top: 16px;
  background: ${(props) => props.theme.colors.primary};
  border-radius: 5px;
  font-weight: 700;
  font-size: 0.7rem;
  transition: background-color 250ms ease-in-out;

  ${(props) => !props.disabled && hoverCSS}

  ${(props) => props.disabled && disableCSS};
` as any;
