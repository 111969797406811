import React, {
  useState,
  useCallback,
  useEffect,
  ChangeEvent,
  useRef
} from 'react';
import { useField } from "@unform/core";
import { FiCamera } from 'react-icons/fi';

import Col from "../../../../components/Col";
import noImageUrl from '../../../../assets/images/no-image.png';

import { Container } from './styles';

interface IImage {
  onlyRead: boolean;
}

const Image: React.FC<IImage> = ({ onlyRead = false }) => {
  const [file, setFile] = useState<FileList>();
  const { fieldName, defaultValue, registerField } = useField('image');
  const [imageUrl, setImageUrl] = useState(defaultValue || noImageUrl);
  const inputRef = useRef<any>(null);

  const handleChangeImage = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const [selectedFile] = e.target.files as any;
      const reader = new FileReader();

      reader.onload = (e) => {
        const imageData = e.target?.result as any;
        setImageUrl(imageData);
      };

      reader.readAsDataURL(selectedFile);
      setFile(selectedFile);
    }
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: () => file,
      setValue: (_, newValue) => {
        setImageUrl(newValue || noImageUrl);
      },
    });
  }, [fieldName, registerField, file, setImageUrl]);

  return (
    <Col xs={6} md={6} sm={12}>
      <Container>
        <img
          src={imageUrl}
          alt="Foto do módulo"
        />

        {!onlyRead && (
          <label htmlFor="image">
            <FiCamera size={18} />
            <input
              id="image"
              type="file"
              accept="image/*"
              onChange={handleChangeImage}
            />
          </label>
        )}
      </Container>
    </Col>
  );
}

export default Image;
