import React, { useCallback } from "react";
import Button from "@material-ui/core/Button";
import { MdCancel, MdSave } from "react-icons/md";
import { useHistory } from "react-router-dom";

import { Container } from "./styles";

export interface FooterActionsProps {
  loading?: boolean;
  onCancelRoute?: string;
  onCancel?: () => void;
  onSubmit(): void;
}

const FooterActions: React.FC<FooterActionsProps> = ({
  loading,
  onCancelRoute,
  onCancel,
  onSubmit,
}) => {
  const history = useHistory();

  const handleCancel = useCallback(() => {
    if (onCancelRoute) {
      history.replace(onCancelRoute, {});
    }
  }, [onCancelRoute, history]);

  return (
    <Container>
      <Button
        disabled={!!loading}
        startIcon={<MdCancel />}
        variant="contained"
        style={{
          backgroundColor: "#eb5f4a",
          marginRight: 15,
          color: "#fff",
          height: 38,
        }}
        onClick={onCancel ?? handleCancel}
      >
        <span
          style={{ fontWeight: 600, textTransform: "none", fontSize: "0.8rem" }}
        >
          Cancelar
        </span>
      </Button>
      <Button
        startIcon={<MdSave fontSize="18" />}
        disabled={loading}
        variant="contained"
        style={{
          backgroundColor: loading ? "rgba(0, 0, 0, 0.12)" : "#6CBD92",
          color: loading ? "rgba(0, 0, 0, 0.26)" : "#fff",
          width: 107,
          height: 38,
        }}
        onClick={onSubmit}
      >
        <span
          style={{ fontWeight: 600, textTransform: "none", fontSize: "0.8rem" }}
        >
          Salvar
        </span>
      </Button>
    </Container>
  );
};

export default FooterActions;
