import styled from "styled-components";

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
`;

export const Content = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
`;
