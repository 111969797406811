import React, { useRef, useCallback } from "react";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import LayoutEdit from "../../components/Layouts/Edit";
import Form from "./components/Form";

import Request from "../../services/request";
import { dashboardRoute } from "../../routes/config";
import { updateUserInfoRouteApi } from "../../routes/config/api";
import getValidationsErrors from "../../utils/errors/getValidationsErrors";
import { useGlobalLoading } from "../../hooks/GlobalLoadingContext";
import { useAuth } from "../../hooks/AuthContext";

const UserProfile: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { loading, setLoading } = useGlobalLoading();
  const { user, setUser } = useAuth();

  const updateUser = useCallback(
    async (data: any) => {
      try {
        setLoading(true);

        const formattedData = {
          name: data.name,
        };

        if (data.password && data.password_confirmation) {
          Object.assign(formattedData, {
            new_password: data.password,
          });
        }

        const updatedUser = await Request.put(
          `${updateUserInfoRouteApi.path}/${user.id}`,
          formattedData
        );

        if (!!updateUser) {
          setUser(updatedUser);
        }

        toast.success("Perfil atualizado com sucesso!");
        history.push(dashboardRoute.path);
      } catch (err) {
        toast.error("Ops, não foi possível atualizar o perfil!");
      } finally {
        setLoading(false);
      }
    },
    [history, setLoading, setUser, user.id]
  );

  const handleUpdate = useCallback(async () => {
    try {
      formRef.current?.setErrors({});

      const data = formRef.current?.getData() as any;
      const validatePassword =
        !!data.password || !!data.password_confirmation
          ? {
              password: Yup.string()
                .required("Senha deve ter no mínimo 8 digítos")
                .min(8, "No mínimo 8 digitos"),
              password_confirmation: Yup.string()
                .required("Senha deve ter no mínimo 8 digítos")
                .min(8, "No mínimo 8 digitos")
                .oneOf(
                  [Yup.ref("password"), ""],
                  "Senha de confirmação incorreta"
                ),
            }
          : {};

      const schema = Yup.object().shape({
        name: Yup.string().required("Campo Obrigatório"),
        ...validatePassword,
      });

      await schema.validate(data, { abortEarly: false });

      updateUser(data);
    } catch (err) {
      const validationErrors = getValidationsErrors(err);
      formRef.current?.setErrors(validationErrors);
    }
  }, [updateUser]);

  return (
    <LayoutEdit
      breadcrumbs={[{ text: "Editar Perfil" }]}
      navMenuProps={{}}
      footerActionsProps={{
        onSubmit: handleUpdate,
        onCancelRoute: dashboardRoute.path,
        loading,
      }}
    >
      <Form formRef={formRef} />
    </LayoutEdit>
  );
};

export default UserProfile;
