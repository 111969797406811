import React, { useState, createContext, useContext, useCallback } from "react";
import { toast } from "react-toastify";

import Request from "../services/request";

interface IUser {
  id: string;
  name: string;
  email: string | null;
  username: string;
  powerbi_user?: string;
  profile_id: number;
  is_admin?: boolean;
}

interface IAuth {
  signed: boolean;
  token: string | null;
  user: IUser;
}

interface ISignInProps {
  username: string;
  password: string;
}

export interface IAuthContextProps {
  signed: boolean;
  token: string | null;
  user: IUser;
  signIn(props: ISignInProps): Promise<void>;
  signOut(): void;
  setUser(user: IUser): void;
}

const AuthContext = createContext<IAuthContextProps>({} as IAuthContextProps);

export const AuthProvider: React.FC = ({ children }) => {
  const [auth, setAuth] = useState<IAuth>(() => {
    const token = localStorage.getItem("@App:auth:token");
    const user = localStorage.getItem("@App:auth:user");
    const formattedUser = (user ? JSON.parse(user) : {}) as IUser;

    Request.setHeader("Authorization", `Bearer ${token}`);

    return {
      signed: !!token,
      token: token,
      user: formattedUser,
    };
  });

  const signIn = useCallback(async (data: ISignInProps) => {
    try {
      const response = await Request.post("auth", data);

      setAuth((oldAuth) => ({
        ...oldAuth,
        ...response,
        signed: !!response.token,
      }));

      localStorage.setItem("@App:auth:token", response.token);
      localStorage.setItem("@App:auth:user", JSON.stringify(response.user));

      Request.setHeader("Authorization", `Bearer ${response.token}`);

      toast.dismiss();
    } catch (err) {
      toast.error("Usuário ou senha incorretos");
    }
  }, []);

  const signOut = useCallback(() => {
    localStorage.clear();
    setAuth({
      signed: false,
      token: null,
      user: {} as IUser,
    });
  }, []);

  const setUser = useCallback((user) => {
    localStorage.setItem("@App:auth:user", JSON.stringify(user));

    setAuth((oldData) => ({
      ...oldData,
      user,
    }));
  }, []);

  return (
    <AuthContext.Provider value={{ ...auth, signIn, signOut, setUser }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): IAuthContextProps => {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within a AuthProvider");
  }

  return context;
};

export default AuthContext;
