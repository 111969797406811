import React, {
  useState,
  createContext,
  useContext,
  useCallback,
  useEffect,
} from "react";

import Request from "../services/request";
import { useAuth } from "./AuthContext";
import {
  userProfilesRouteApi,
  usersRouteApi,
  favoriteModulesRouteApi,
} from "../routes/config/api";
import dynamicSort from "../utils/dynamicSort";

interface IProfile {
  id: number;
  name: string;
}

interface IModule {
  id: number;
  name: string;
  group_id?: string | null;
  report_id?: string | null;
  dataset_id?: string | null;
  is_favorite: boolean;
  route: string | null;
}

export interface IProfileModulesContextProps {
  loading: boolean;
  profile: IProfile;
  modules: IModule[];
  lastAccesses: IModule[];
  modulesPath: string[];
  isFavorites: boolean;
  showingFilteredModules: boolean;
  updateModule(moduleId: number): void;
  setLastAccess(lastAccess: IModule): void;
  removeLastAccesses(): void;
  setFilterValue(value: string): void;
  setFavorites(showFavorites: boolean): void;
}

export interface IProfileModulesContext {
  profile: IProfile;
  modules: IModule[];
}

const ProfileModulesContext = createContext<IProfileModulesContextProps>(
  {} as IProfileModulesContextProps
);
const dashboardModule = {
  id: 1000001,
  name: "Dashboard",
  route: "/dashboard",
};
const defaultProfileModules: any = {
  profile: {},
  modules: [],
};
const lastAccessesKey = '@App:profileModules:lastAccesses';

export const ProfileModulesProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [modulesPath, setModulesPath] = useState<string[]>([]);
  const [data, setData] = useState<IProfileModulesContext>(
    defaultProfileModules
  );
  const [defaultModules, setDefaultModules] = useState<IModule[]>(
    defaultProfileModules.modules
  );
  const [lastAccesses, setLastAccesses] = useState<IModule[]>(() => {
    const lastAccesses = localStorage.getItem(lastAccessesKey);
    const formattedLastAccesses = JSON.parse(lastAccesses || '[]') as IModule[];

    return formattedLastAccesses;
  });
  const [showingFilteredModules, setShowingFilteredModules] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [showFavorites, setShowFavorites] = useState(false);
  const { signed, user, setUser } = useAuth();

  const copyArray = useCallback((arr: any[]) => {
    return JSON.parse(JSON.stringify(arr));
  }, []);

  const formatProfileModules = useCallback((items: any[]): IModule[] => {
    const newProfileModules: IModule[] = [];
    const newModulesPath: string[] = [];
    const sortedItems = items.sort(dynamicSort("id", "ASC"));

    sortedItems.forEach((item) => {
      const modulePath = item.route?.startsWith('/')
        ? `${item.route}/${item.id}`
        : `/${item.route}/${item.id}`
      const pathRoute = `/report${modulePath}`;

      newProfileModules.push({
        ...item,
        route: pathRoute,
      });
      newModulesPath.push(pathRoute);
    });

    setModulesPath(newModulesPath);

    return newProfileModules;
  }, []);

  const findProfileModules = useCallback(async () => {
    try {
      const modules: any = [dashboardModule];
      const profile: any = {};
      const userData = await Request.get(`${usersRouteApi.path}/${user.id}`);

      if (!!userData.user_profiles?.length) {
        const response = await Request.get(
          `${userProfilesRouteApi.path}/${user.id}`
        );
        const formattedProfileModules = formatProfileModules(response);
        modules.push(...formattedProfileModules);
      }
      const moduleDataWithoutMemoryReference = copyArray(modules);

      setData({ profile, modules: moduleDataWithoutMemoryReference });
      setDefaultModules(modules);
      setUser(userData);
    } finally {
      setLoading(false);
    }
  }, [copyArray, formatProfileModules, setUser, user.id]);

  const filterModules = useCallback(() => {
    if (!filterValue) {
      let modulesFilter = copyArray(defaultModules) as IModule[];
      if (showFavorites) {
        modulesFilter = modulesFilter.filter((module) => module.is_favorite);
      }

      setShowingFilteredModules(false);
      setData((oldData) => ({ ...oldData, modules: modulesFilter }));
      return;
    }

    let modulesFilter = copyArray(defaultModules) as IModule[];

    if (showFavorites) {
      modulesFilter = modulesFilter.filter((module) => module.is_favorite);
    }

    const formattedFilter = filterValue.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    const searchRegEx = new RegExp(formattedFilter.toLowerCase());
    const newModules = modulesFilter.filter(
      mod => searchRegEx.test(mod.name.toLowerCase())
    );

    setShowingFilteredModules(true);
    setData((oldData) => ({ ...oldData, modules: newModules }));
  }, [copyArray, defaultModules, filterValue, showFavorites]);

  const handleSetLastAccess = useCallback(
    (newLastAccess: IModule) => {
      const newLastAccesses = lastAccesses.filter(
        (lastAccess) => lastAccess.id !== newLastAccess.id
      );
      const MAX_LAST_ACCESS_ITEMS = 6;
      const formattedData = [newLastAccess, ...newLastAccesses].slice(
        0,
        MAX_LAST_ACCESS_ITEMS
      );

      localStorage.setItem(lastAccessesKey, JSON.stringify(formattedData));
      setLastAccesses(formattedData);
    },
    [lastAccesses]
  );

  const handleRemoveLastAccesses = useCallback(
    () => {
      localStorage.setItem(lastAccessesKey, JSON.stringify([]));
      setLastAccesses([]);
    },
    []
  );

  const handleUpdateModule = useCallback((moduleId: number) => {
    const isFavoriteModule = defaultModules.find(
      module => module.id === moduleId && module.is_favorite
    );

    if (isFavoriteModule) {
      Request.del(`${favoriteModulesRouteApi.path}/module/${moduleId}`);
    } else {
      Request.post(favoriteModulesRouteApi.path, { module_id: moduleId});
    }

    const newDefaultModules = defaultModules.map((defaultModule) => {
      if (defaultModule.id === moduleId) {
        defaultModule.is_favorite = !defaultModule.is_favorite;
      }

      return defaultModule;
    });
    const newModulesData = data.modules.map((moduleData) => {
      if (moduleData.id === moduleId) {
        moduleData.is_favorite = !moduleData.is_favorite;
      }

      return moduleData;
    });

    setDefaultModules(newDefaultModules);
    setData((oldData) => ({ ...oldData, modules: newModulesData }));
  }, [data.modules, defaultModules])

  const handleSetFavorites = useCallback(
    (newShowFavoritesValue: boolean) => {
      setShowFavorites(newShowFavoritesValue);
    },
    []
  );

  const handleSetFilterValue = useCallback(
    (newValue: string) => setFilterValue(newValue),
    []
  );

  useEffect(() => {
    if (signed) {
      findProfileModules();
    }
  }, [findProfileModules, signed]);

  useEffect(() => {
    filterModules();
  }, [filterModules, showFavorites, filterValue]);

  return (
    <ProfileModulesContext.Provider
      value={{
        ...data,
        loading,
        modulesPath,
        lastAccesses,
        setFilterValue: handleSetFilterValue,
        updateModule: handleUpdateModule,
        setLastAccess: handleSetLastAccess,
        removeLastAccesses: handleRemoveLastAccesses,
        showingFilteredModules,
        isFavorites: showFavorites,
        setFavorites: handleSetFavorites,
      }}
    >
      {children}
    </ProfileModulesContext.Provider>
  );
};

export const useProfileModules = (): IProfileModulesContextProps => {
  const context = useContext(ProfileModulesContext);

  if (!context) {
    throw new Error(
      "useProfileModules must be used within a ProfileModulesProvider"
    );
  }

  return context;
};

export default ProfileModulesContext;
