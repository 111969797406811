import React, { useState, useImperativeHandle, forwardRef, memo } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";
import PropTypes from "prop-types";

const Transition = forwardRef(function Transition(props: any, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
}) as any;

const callbacks = {
  cancel: () => {},
  confirm: () => {},
};

function ConfirmationDialogComponent(
  { title, description, cancelText, confirmText }: any,
  dialogRef: any
) {
  const [open, setOpen] = useState(false);

  useImperativeHandle(dialogRef, () => ({
    open: () => setOpen(true),
    close: () => setOpen(false),
    onCancel: (callback: any) => {
      callbacks.cancel = callback;
    },
    onConfirm: (callback: any) => {
      callbacks.confirm = callback;
    },
  }));

  function handleCancel() {
    setOpen(false);
    callbacks.cancel();
  }

  function handleConfirm() {
    setOpen(false);
    callbacks.confirm();
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleCancel}
      fullWidth
      maxWidth="xs"
      style={{ zIndex: 9999 }}
    >
      <DialogTitle style={{ fontSize: "0.8rem" }} id="alert-dialog-slide-title">
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          style={{ fontSize: "0.8rem", color: "#777" }}
          id="alert-dialog-slide-description"
        >
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancelText && (
          <Button
            style={{ fontSize: "0.75rem", color: "#00BCF2" }}
            onClick={handleCancel}
            color="primary"
          >
            {cancelText}
          </Button>
        )}
        <Button
          style={{ fontSize: "0.75rem", color: "#00BCF2" }}
          onClick={handleConfirm}
          color="primary"
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

const ConfirmationDialog = forwardRef(ConfirmationDialogComponent);

ConfirmationDialog.defaultProps = {
  title: "",
  description: "",
  cancelText: "Cancelar",
  confirmText: "Confirmar",
};

ConfirmationDialog.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  cancelText: PropTypes.string,
  confirmText: PropTypes.string,
};

export default memo(ConfirmationDialog);
