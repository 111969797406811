import React, { useRef, useState, useCallback, useEffect } from "react";
import Select, {
  OptionTypeBase,
  Props as SelectProps,
  OptionsType,
  GroupedOptionsType,
} from "react-select";
import { useField } from "@unform/core";
import { shade } from "polished";

import Col from "../../Col";

type Metrics =
  | boolean
  | 12
  | 6
  | 2
  | 1
  | "auto"
  | 3
  | 4
  | 5
  | 7
  | 8
  | 9
  | 10
  | 11
  | undefined;

export interface MetricsProps {
  xs?: Metrics;
  sm?: Metrics;
  md?: Metrics;
}

export type ISelectOption =
  | GroupedOptionsType<OptionTypeBase>
  | OptionsType<OptionTypeBase>;

export interface InputSelectProps
  extends SelectProps<OptionTypeBase>,
    MetricsProps {
  name: string;
  label: string;
  helperText?: string;
  options: ISelectOption[];
  onSelect?: (option: ISelectOption) => void;
}

const InputSelect: React.FC<InputSelectProps> = ({
  name,
  label,
  options,
  onSelect,
  xs = 12,
  sm = 12,
  md = 12,
  helperText,
  ...rest
}) => {
  const [state, setState] = useState<ISelectOption>([]);
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const handleChange = useCallback(
    (newState: any) => {
      setState(newState);
      onSelect && onSelect(newState);
    },
    [onSelect]
  );

  useEffect(() => {
    if (fieldName) {
      registerField({
        name: fieldName,
        ref: selectRef.current,
        getValue: (ref: any) => {
          return state;
        },
        setValue: (ref: any, value: any) => {
          setState(value);
        },
      });
    }
  }, [fieldName, registerField, rest.isMulti, state]);

  useEffect(() => {
    setState(defaultValue);
  }, [defaultValue]);

  return (
    <Col xs={xs} sm={sm} md={md}>
      <Select
        isClearable
        {...rest}
        ref={selectRef}
        name={name}
        defaultValue={defaultValue}
        classNamePrefix="react-select"
        styles={{
          input: () => ({
            fontSize: "0.75rem",
            color: "#777",
          }),
          singleValue: () => ({
            fontSize: "0.75rem",
          }),
          placeholder: () => ({
            fontSize: "0.75rem",
          }),
          menu: (menuStyle) => ({
            ...menuStyle,
            zIndex: 1100,
            top: 34,
          }),
          menuList: (style: any) => ({
            ...style,
            fontSize: "0.75rem",
          }),
          control: () => ({
            display: "flex",
            flexDirection: "row",
            border: `1px solid ${error ? "#eb5f4a" : "rgba(29, 37, 59, 0.2)"}`,
            color:
              // @ts-ignore
              (state && !!state[0]?.options?.length) || !!state?.value
                ? "#656565"
                : "#aaa",
            width: "100%",
            borderRadius: 4,
            "&:focus-within": {
              border: `1px solid ${error ? "#eb5f4a" : "#00BCF2"}`,
            },
            "&:hover": {
              border: "1px solid #00BCF2",
            },
          }),
          multiValue: (styles) => ({
            ...styles,
            backgroundColor: "rgba(0,188,242,0.15)",
          }),
          multiValueLabel: (styles) => ({
            ...styles,
            padding: "3px 8px",
            color: "#00BCF2",
            fontWeight: 500,
            fontSize: "0.7rem",
          }),
          multiValueRemove: (styles) => ({
            ...styles,
            color: "#FFF",
            backgroundColor: "#00BCF2",
            cursor: "pointer",
            transition: "background-color 150ms ease-in-out",
            ":hover": {
              backgroundColor: shade(0.2, "#00BCF2"),
            },
          }),
        }}
        theme={(theme) => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary25: "#dedede",
            primary: "#00BCF2",
            neutral0: "#FFF",
            neutral50: "#bbb",
            neutral60: error ? "#eb5f4a" : "#00BCF2",
            neutral80: "#656565",
          },
        })}
        onChange={handleChange}
        options={options}
        placeholder={label}
        value={state}
        noOptionsMessage={() => "Ops, nenhuma informação encontrada"}
      />
      {!!helperText && (
        <span
          style={{
            fontSize: "11px",
            color: error ? "#eb5f4a" : "#aaa",
            margin: "0 14px",
          }}
        >
          {error || helperText}
        </span>
      )}
    </Col>
  );
};

export default InputSelect;
