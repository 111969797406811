import React from "react";
import { Switch } from "react-router-dom";

import Route from "../../services/route";
import RouteWrapper from "../RouteWrapper";
import { Create, Edit, List, Show } from "../../pages/Profiles";

export const createProfileRoute = new Route("/profiles/new");

export const editProfileRoute = new Route("/profiles/:id/edit");

export const listProfilesRoute = new Route("/profiles");

export const showProfileRoute = new Route("/profiles/:id/show");

export const ProfilesRouteComponent: React.FC = () => (
  <Switch>
    <RouteWrapper
      exact
      isPrivate
      path={createProfileRoute.path}
      component={Create}
    />
    <RouteWrapper
      exact
      isPrivate
      path={editProfileRoute.path}
      component={Edit}
    />
    <RouteWrapper
      exact
      isPrivate
      path={listProfilesRoute.path}
      component={List}
    />
    <RouteWrapper
      exact
      isPrivate
      path={showProfileRoute.path}
      component={Show}
    />

    <RouteWrapper
      isPrivate
      redirectIfNotExact
      path={listProfilesRoute.path}
      component={List}
    />
  </Switch>
);
