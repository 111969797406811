import React, { useState, useCallback, useRef } from 'react';
import InputBase from '@material-ui/core/InputBase';
import { darken, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { TiDeleteOutline } from 'react-icons/ti';
import { AiOutlineStar, AiFillStar } from 'react-icons/ai';

import { useProfileModules } from '../../../hooks/ProfileModulesContext';

const useStyles = makeStyles((theme) => ({
  search: {
    display: 'flex',
    justifyContent: 'flex-end',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: darken('#00BCF2', 0.15),
    '&:hover': {
      backgroundColor: darken('#00BCF2', 0.2),
    },
    margin: '0 16px',
    width: '100%',
    maxWidth: 'none',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: '100%',
      minWidth: '300px',
      maxWidth: '500px',
    },
  },
  searchIcon: {
    minWidth: 50,
    padding: theme.spacing(0, 1, 0, 1),
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    padding: theme.spacing(1, 0, 1, 0),
    width: '100%',
    '&::placeholder': {
      opacity: 0.77,
    }
  },
  searchBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    minWidth: 45,
    cursor: 'pointer',
  },
  deleteIcon: {
    fontSize: 20,
    color: '#fff',
  },
  favoriteBtn: {
    backgroundColor: darken('#00BCF2', 0.3),
    border: 'none',
    minWidth: 40,
    borderTopRightRadius: theme.shape.borderRadius,
    borderBottomRightRadius: theme.shape.borderRadius,
    cursor: 'pointer',
  },
  favoriteIcon: {
    fontSize: 20,
    color: '#fff',
  },
}));

const InputSearch: React.FC = () => {
  const [value, setValue] = useState('');
  const [enableFavorites, setEnableFavorites] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const classes = useStyles();
  const { setFilterValue, setFavorites } = useProfileModules();

  const handleChange = useCallback((e: any) => {
    const newValue = e.target.value;

    setValue(newValue);
    setFilterValue(newValue);
  }, [setFilterValue]);

  const handleClick = useCallback(() => {
    setValue('');
    setFilterValue('');

    inputRef.current?.focus();
  }, [setFilterValue]);

  const handleSetFavorites = useCallback(() => {
    const isFavorite = !enableFavorites;

    setEnableFavorites(isFavorite);
    setFavorites(isFavorite);
  }, [enableFavorites, setFavorites]);

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon style={{ fontSize: 22 }} />
      </div>
      <InputBase
        ref={inputRef}
        placeholder="Pesquisar…"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        onChange={handleChange}
        value={value}
        inputProps={{ 'aria-label': 'search' }}
      />

      {!!value && (
        <button className={classes.searchBtn} onClick={handleClick}>
          <TiDeleteOutline className={classes.deleteIcon} />
        </button>
      )}
      <button className={classes.favoriteBtn} onClick={handleSetFavorites}>
        {enableFavorites
          ? <AiFillStar className={classes.favoriteIcon} />
          : <AiOutlineStar className={classes.favoriteIcon} />
        }
      </button>
    </div>
  );
}

export default InputSearch;
