import styled, { keyframes } from "styled-components";

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-70px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  .login {
    position: relative;
    width: 510px;
    height: 370px;
    margin: auto;
    background-color: ${(props) => props.theme.colors.white};
    border-radius: 7px;
    box-shadow: 0 1px 20px 0 ${(props) => props.theme.colors.boxShadow};

    animation: ${appearFromLeft} 1s;

    .card-header {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35%;

      img {
        height: 70px;
        margin-top: 20px;
      }
    }

    .card-body {
      height: 65%;
      padding: 15px;
    }

    .card-footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 15%;
      padding: 15px 25px;

      .forgot-password {
        text-decoration: none;
        font-size: 0.75rem;
        color: ${(props) => props.theme.colors.text};
        opacity: 0.5;

        &:hover {
          color: ${(props) => props.theme.colors.primary};
          opacity: 0.8;
        }
      }

      .signup {
        text-decoration: none;
        font-size: 0.75rem;
        color: ${(props) => props.theme.colors.primary};
        font-weight: 700;
        opacity: 0.9;
        transition: transform 100ms ease-in;

        &:hover {
          transform: scale(1.03);
        }
      }
    }
  }

  footer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;

    span {
      color: ${(props) => props.theme.colors.text};
      opacity: 0.5;
      font-size: 0.78rem;
      text-align: center;
    }
  }

  @media (max-width: 768px) {
    .login {
      width: 100%;
      height: 400px;
      animation: none;
    }

    footer span {
      font-size: 0.85rem;
    }
  }
`;
