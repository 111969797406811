import styled from "styled-components";
import MUITextField from "@material-ui/core/TextField";
import { withStyles } from "@material-ui/core/styles";

import { theme } from "../../../styles/theme";

export const TextFieldStyle = withStyles({
  root: {
    "& label.Mui-focused": {
      color: theme.light.colors.primary,
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "rgba(29, 37, 59, 0.2)",
      },
      "&.Mui-focused fieldset": {
        border: `1px solid ${theme.light.colors.primary}`,
      },
    },
  },
})(MUITextField);

type TTextField = typeof MUITextField;

export const TextField = styled(TextFieldStyle as TTextField)`
  width: 100%;
  margin-bottom: 15px !important;

  svg {
    color: #bbbbbb;
  }

  label {
    color: ${(props) => props.theme.colors.white};
    font-size: 16px;
    padding: 0 8px 0 0;
  }

  & .MuiOutlinedInput-root {
    background-color: ${(props) => props.theme.colors.white};
    height: 38px;
    color: ${(props) => props.theme.colors.text};
  }

  &
    .MuiOutlinedInput-root:hover:not(:focus-within):not(.Mui-error)
    .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${(props) => props.theme.colors.primary};
  }

  &
    .MuiOutlinedInput-root:hover:focus-within:not(.Mui-error)
    .MuiOutlinedInput-notchedOutline {
    border: 1px solid ${(props) => props.theme.colors.primary};
  }

  & .MuiOutlinedInput-notchedOutline {
    border: 1px solid #bbbbbb;
  }

  & .MuiFormHelperText-root {
    color: #aaa;
    font-size: 11px;
  }

  & .MuiOutlinedInput-input {
    padding: 12.5px 14px;
  }

  &:hover p:not(.Mui-error),
  label {
    color: #aaa;
  }

  &:focus-within p:not(.Mui-error) {
    color: ${(props) => props.theme.colors.primary};
  }

  &:focus-within div:not(.Mui-error) svg {
    color: ${(props) => props.theme.colors.primary};
  }

  &:focus-within .Mui-error svg {
    color: ${(props) => props.theme.colors.red};
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0 30px ${(props) => props.theme.colors.white} inset !important;
    -webkit-text-fill-color: ${(props) => props.theme.colors.text} !important;
    border-radius: 0;
  }

  & .MuiOutlinedInput-adornedStart {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      margin-left: 0;
    }
  }

  & .MuiInputBase-input {
    color: ${(props) => props.theme.colors.inputText} !important;
    caret-color: ${(props) => props.theme.colors.inputText};
    font-size: 0.75rem !important;
  }

  & .PrivateNotchedOutline-legendLabelled {
    display: none !important;
  }

  & [class^="PrivateNotchedOutline-legendLabelled"],
  [class*=" PrivateNotchedOutline-legendLabelled"] {
    font-size: 0.6rem !important;
  }
`;
