import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";

import backgroundImg from "../../assets/images/background.png";

export const Container = styled.div`
  background-image: url(${backgroundImg});
  width: 100%;

  .banner {
    width: 100%;
    min-height: 70px;

    @media (max-width: 600px) {
      display: none;
    }
  }

  .content {
    padding: 24px;
  }

  .title-available-dashs {
    padding-bottom: 10px;
  }

  .available-dashs {
    padding: 0 32px;

    .no-data {
      padding: 16px 24px;
    }
  }

  .title-last-accesses {
    padding: 32px 0 10px 0;
  }

  .last-accesses {
    padding: 0 32px;
  }

  .link-item {
    position: relative;

    .favorite-btn {
      position: absolute;
      background-color: transparent;
      border: none;
      bottom: 4px;
      right: 7px;
      width: 35px;
      height: 35px;
      z-index: 1000;
      cursor: pointer;
      border-radius: 50%;

      svg {
        font-size: 22px;
        color: ${(props) => props.theme.colors.primary};
      }
    }
  }

  @media (max-width: 800px) {
    .available-dashs {
      padding: 0;
    }

    .last-accesses {
      padding: 0;
    }
  }
`;

export const Link = styled(RouterLink)`
  display: flex;
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};
  color: ${props => props.theme.colors.text};
  text-decoration: none;
  border-radius: 5px;
  box-shadow: 0 1px 1px 0 rgb(0 0 0 / 10%), 0 -1px 2px 0 rgb(0 0 0 / 15%);
  transition: transform 90ms ease-in-out;

  &:hover {
    box-shadow: 0 1px 1px 0 rgb(0 0 0 / 15%), 0 -1px 0px 0 rgb(0 0 0 / 20%);
    transform: scale(1.06);
  }

  .image {
    height: 140px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .description {
    height: 55px;
    padding: 10px 8px;

    span {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      font-size: 0.75rem;
      line-height: 18px;
    }
  }

  @media(max-width: 600px) {
    .image {
      height: 200px;
    }
  }
`;
