import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { StylesProvider } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Routes from "./routes";
import GlobalStyle from "./styles/global";
import { theme } from "./styles/theme";
import { GlobalLoadingProvider } from "./hooks/GlobalLoadingContext";
import { AuthProvider } from "./hooks/AuthContext";
import { ProfileModulesProvider } from "./hooks/ProfileModulesContext";

toast.configure();

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <StylesProvider>
          <ThemeProvider theme={theme.light}>
            <GlobalLoadingProvider>
              <AuthProvider>
                <ProfileModulesProvider>
                  <GlobalStyle />
                  <Routes />
                </ProfileModulesProvider>
              </AuthProvider>
            </GlobalLoadingProvider>
          </ThemeProvider>
        </StylesProvider>
      </BrowserRouter>
    </div>
  );
}

export default App;
