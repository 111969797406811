import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  max-width: 450px;

  img {
    width: 100%;
    height: 220px;
  }

  label {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 42px;
    height: 42px;
    background-color: ${(props) => props.theme.colors.primary};
    border: none;
    border-radius: 50%;
    bottom: 7px;
    right: 7px;
    cursor: pointer;
    transition: background-color 200ms;

    input {
      display: none;
    }

    svg {
      width: 20px;
      height: 20px;
      color: ${(props) => props.theme.colors.white};
    }

    &:hover {
      background-color: ${(props) => shade(0.2, props.theme.colors.primary)};
    }
  }
`;
