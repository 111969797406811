import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { FormHandles } from "@unform/core";
import { toast } from "react-toastify";

import Form from "../../../../components/Form";
import InputText from "../../../../components/Input/Text";
import InputSelect from "../../../../components/Input/Select";
import Col from "../../../../components/Col";
import Checkbox from "../../../../components/Input/Checkbox";
import ModuleImage from "../Image";

import Request from "../../../../services/request";
import { modulesRouteApi } from "../../../../routes/config/api";

const inputRouteMask = ['/', ...Array(100).fill(/./)];

interface FormProps {
  isShowPage?: boolean;
  isEditPage?: boolean;
  formRef: React.RefObject<FormHandles>;
  onSubmit?: (data: any) => void;
}

const permissionsSelect = [
  { label: "Permissão para Leitura", value: 0 },
  { label: "Permissão para Leitura/Escrita", value: 1 },
  { label: "Permissão para Cópia", value: 2 },
  { label: "Permissão para Criar", value: 4 },
  { label: "Permissão Total", value: 7 },
];

const getPermission = (permission: number) => {
  const selectedPermission = permissionsSelect.find(
    (permissionSelect) => permissionSelect.value === permission
  );

  return selectedPermission;
};

const FormComponent: React.FC<FormProps> = ({
  formRef,
  isShowPage = false,
  isEditPage = false,
}) => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams<any>();

  const getHelperText = useCallback(
    () => {
      return isShowPage ? "" : "Campo Obrigatório";
    },
    [isShowPage]
  );

  useEffect(() => {
    async function findModule(): Promise<void> {
      if (id) {
        try {
          setLoading(true);

          const data = await Request.get(`${modulesRouteApi.path}/${id}`);
          if (!!data) {
            formRef.current?.setData({
              ...data,
              permissions: getPermission(data.permissions),
              group_id: data.group_id || undefined,
              report_id: data.report_id || undefined,
              dataset_id: data.dataset_id || undefined,
              route: data.route || undefined,
              image: data.image_url,
              inactive: !!data.inactive_at,
            });
          }
        } catch (err) {
          toast.error("Ops, não foi possível carregar as informações");
        } finally {
          setLoading(false);
        }
      }
    }

    findModule();
  }, [formRef, id]);

  return (
    <Form formRef={formRef} loading={loading} disabledForm={isShowPage}>
      <InputText
        name="name"
        label="Nome"
        helperText={isShowPage ? "" : "Campo Obrigatório"}
        autoComplete="off"
        sm={6}
        md={6}
      />
      <InputText
        name="group_id"
        label="ID do grupo"
        autoComplete="off"
        helperText={getHelperText()}
        sm={6}
        md={6}
      />
      <InputText
        name="report_id"
        label="ID do relatório"
        helperText={getHelperText()}
        autoComplete="off"
        sm={6}
        md={6}
      />
      <InputText
        name="dataset_id"
        label="ID do conjunto de dados"
        helperText={getHelperText()}
        autoComplete="off"
        sm={6}
        md={6}
      />
      <InputText
        name="route"
        label="URL"
        md={6}
        helperText={getHelperText()}
        inputMask={inputRouteMask}
      />

      <InputSelect
        name="permissions"
        label="Selecione uma permissão"
        md={6}
        helperText={getHelperText()}
        isClearable={false}
        isSearchable={false}
        options={permissionsSelect as any}
      />

      <ModuleImage onlyRead={isShowPage} />

      <Col xs={6} md={6} sm={12}>
        <Checkbox
          name="filter_pane_enabled"
          label="Mostrar painel de filtros"
        />
        <Checkbox
          name="nav_content_pane_enabled"
          label="Mostar painel de navegação"
        />
        {(isShowPage || isEditPage) && (
          <Checkbox
            name="inactive"
            label="Inativar módulo"
          />
        )}
      </Col>
    </Form>
  );
};

export default FormComponent;
