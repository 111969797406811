import React from "react";

import LayoutList from "../../components/Layouts/List";

import { modulesRouteApi } from "../../routes/config/api";
import {
  createModuleRoute,
  showModuleRoute,
  editModuleRoute,
} from "../../routes/config";

import ExportToXML from '../../components/ExportToXML';

import { Status } from './styles';

const List: React.FC = () => {
  return (
    <LayoutList
      breadcrumbs={[{ text: "Módulos" }]}
      navMenuProps={{
        StartComponent: () => (
          <ExportToXML
            filename="modulos.csv"
            fetchUrl={`${modulesRouteApi.path}/export`}
            headers={[
              'CÓDIGO',
              'NOME',
              'SITUAÇÃO',
              'DATA CRIAÇÃO',
              'DATA ULT. ALTERAÇÃO',
              'NOME USUÁRIO ATUALIZAÇÃO',
              'PERFIS ASSOCIADOS'
            ]}
            columns={[
              'id',
              'name',
              'status',
              'created_at',
              'updated_at',
              'operator_name',
              'profiles',
            ]}
          />
        ),
        newRoute: createModuleRoute.path,
      }}
      tableProps={{
        requestOptions: {
          url: modulesRouteApi,
        },
        columns: [
          { name: "id", title: "Código" },
          { name: "name", title: "Nome" },
          { name: "inactive_at", title: "Status" },
          { name: "actions", title: "Ações" },
        ],
        columnsProperties: [
          { columnName: "id", width: 170 },
          { columnName: "inactive_at", width: 170 },
          { columnName: "actions", width: 150, align: "center" },
        ],
        customActions: ({ id }) => ({
          actions: ["show", "edit", "delete"],
          paths: {
            edit: editModuleRoute.build({ id }),
            show: showModuleRoute.build({ id }),
            delete: `${modulesRouteApi.path}/${id}`,
          },
        }),
        dataTypeProvider: [
          {
            columnName: "id",
            formatterComponent: ({ row }) => (
              <div style={{ fontSize: "0.75rem" }}>
                {String(row.id).padStart(6, "0")}
              </div>
            ),
          },
          {
            columnName: "inactive_at",
            formatterComponent: ({ row }) => {
              const isInactive = !!row.inactive_at;

              return (
                <Status isInactive={isInactive ? 'true' : 'false'}>
                  {isInactive ? 'Inativo' : 'Ativo'}
                </Status>
              )
            },
          },
        ],
      }}
    />
  );
};

export { List };
