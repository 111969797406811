import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from "react";
import {
  SortingState,
  PagingState,
  CustomPaging,
  IntegratedSorting,
  DataTypeProvider,
  Column,
  SearchState
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table as MUITable,
  TableHeaderRow,
  PagingPanel,
  DragDropProvider,
  TableFixedColumns,
  Toolbar,
  SearchPanel
} from "@devexpress/dx-react-grid-material-ui";
import { toast } from "react-toastify";

import NoDataRow from "./components/NoDataRow";
import Loading from "./components/Loading";
import { useQueryParams } from "../../hooks/QueryParamsContext";
import Actions, { ActionsProps } from "./components/Actions";
import ConfirmationDialog from "../ConfirmationDialog";

import Request from "../../services/request";
import Route from "../../services/route";

import {
  Container,
  Paper,
  TableHead,
  TableCell,
  PagingPanelContainer,
} from "./styles";

const actionColumnName = "actions";

interface ValueFormatterProps {
  column: Column;
  row?: any;
  value: any;
}

export interface TableProps {
  requestOptions: {
    url: Route;
  };
  columns: Column[];
  columnsProperties: MUITable.ColumnExtension[];
  customActions?: (row: any) => ActionsProps;
  dataTypeProvider?: Array<{
    columnName: string;
    formatterComponent(data: ValueFormatterProps): React.FC | any;
  }>;
}

let searchValueTimeout: any = null;

const Table: React.FC<TableProps> = ({
  columns,
  columnsProperties,
  customActions,
  dataTypeProvider,
  requestOptions,
}) => {
  const [rows, setRows] = useState<any>([]);
  const [sorting, setSorting] = useState<any>([]);
  const [pageSizes] = useState([5, 10, 20]);
  const [dataProviderKeys] = useState(
    useMemo(() => columns.map((column) => column.name), [columns])
  );
  const [rightFixedColumns] = useState([actionColumnName]);
  const [loading, setLoading] = useState(true);
  const { params, setParams, getPath } = useQueryParams();
  const requestSource = useMemo(() => Request.CancelToken.source(), []);
  const searchRecords = useRef(() => {});
  const confirmationDialogRef = useRef(null);

  const setError = useCallback((message: string): void => {
    toast.error(message);
    setLoading(false);
  }, []);

  searchRecords.current = useCallback(
    async () => {
      try {
        const { url } = requestOptions;

        setLoading(true);

        const response = await Request.get(`${getPath(url)}&q=${params.searchValue}`, {
          cancelToken: requestSource.token,
        });

        setLoading(false);
        setRows(response.data);
        setParams({ total: response.meta.total });
      } catch (error) {
        if (!Request.isCancel(error)) {
          setError(
            "Não foi possível buscar os dados. Tente novamente mais tarde!"
          );
        }
      }
    },
    [
      getPath,
      requestOptions,
      requestSource.token,
      setError,
      setParams,
      params.searchValue,
    ]
  );

  const getRowId = useCallback((row: any): number => row.id, []);

  const onDelete = useCallback(
    (deletePath) => {
      const { current: dialog } = confirmationDialogRef as any;

      dialog.open();
      dialog.onConfirm(async () => {
        try {
          setLoading(true);

          await Request.del(deletePath, {
            cancelToken: requestSource.token,
          });

          searchRecords.current();
        } catch (err) {
          if (!Request.isCancel(err)) {
            setError("Ops, ocorreu um erro ao remover o registro!");
          }
        }
      });
    },
    [requestSource.token, searchRecords, setError]
  );

  const onFormatterComponent = useCallback(
    ({ value, row, column }) => {
      if (column.name === actionColumnName) {
        const actions = customActions && customActions(row);
        return (
          <Actions
            actions={[]}
            paths={{
              show: "",
              edit: "",
            }}
            onDelete={onDelete}
            {...actions}
          />
        );
      }

      if (dataTypeProvider) {
        const [columnOverride] = dataTypeProvider.filter(
          (colOver) => colOver.columnName === column.name
        );

        if (columnOverride) {
          const { formatterComponent } = columnOverride;
          return formatterComponent({ value, row, column });
        }
      }

      return <div style={{ fontSize: "0.75rem" }}>{value}</div>;
    },
    [customActions, dataTypeProvider, onDelete]
  );

  const handleSearchValueChange = useCallback((value: string) => {
    searchValueTimeout && clearTimeout(searchValueTimeout);
    searchValueTimeout = setTimeout(() => {
      setParams({ page: 0, searchValue: value });
    }, 250);
  }, [setParams]);

  useEffect(
    () => (): void => {
      requestSource.cancel("Table component unmounted");
    },
    [requestSource]
  );

  useEffect(() => {
    searchRecords.current();
  }, [
    params.searchValue,
    params.page,
    params.per_page,
    sorting
  ]);

  return (
    <Container>
      <Paper>
        <Grid rows={rows} columns={columns} getRowId={getRowId}>
          <SortingState
            sorting={sorting}
            onSortingChange={([newSorting]): void => {
              setParams({
                sort: newSorting.columnName,
                direction: newSorting.direction,
              });
              setSorting([newSorting]);
            }}
          />
          <PagingState
            currentPage={params.page}
            onCurrentPageChange={(newPage): void => {
              setParams({ page: newPage });
            }}
            pageSize={params.per_page}
            onPageSizeChange={(perPage): void => {
              setParams({ per_page: perPage });
            }}
          />
          <SearchState onValueChange={handleSearchValueChange} />
          <CustomPaging totalCount={params.total} />

          <DataTypeProvider
            for={dataProviderKeys}
            formatterComponent={onFormatterComponent}
          />

          <IntegratedSorting />
          <DragDropProvider />
          <Toolbar />
          <SearchPanel />

          <MUITable
            columnExtensions={columnsProperties}
            headComponent={TableHead}
            cellComponent={(props): any => {
              return <TableCell {...props} />;
            }}
            noDataRowComponent={({ children }): any => (
              // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
              // @ts-ignore
              <NoDataRow loading={loading} colSpan={children?.length || 0} />
            )}
          />
          <TableHeaderRow showSortingControls />
          <TableFixedColumns rightColumns={rightFixedColumns} />
          <PagingPanel
            containerComponent={({ totalCount, ...props }): any => {
              return (
                <PagingPanelContainer {...props} totalCount={params.total} />
              );
            }}
            messages={{
              showAll: "Tudo",
              rowsPerPage: "Registros por página",
              info: ({ from, to, count }): string =>
                `${from}-${to} de ${count}`,
            }}
            pageSizes={pageSizes}
          />
        </Grid>

        {loading && <Loading />}
      </Paper>
      <ConfirmationDialog
        ref={confirmationDialogRef}
        title="Atenção"
        description="Deseja excluir este registro?"
      />
    </Container>
  );
};

export default Table;
