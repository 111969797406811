import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { FormHandles } from "@unform/core";
import { toast } from "react-toastify";
import { MdRemoveRedEye } from "react-icons/md";
import { IoMdEyeOff } from "react-icons/io";

import Form from "../../../../components/Form";
import InputText from "../../../../components/Input/Text";
import InputSelect from "../../../../components/Input/Select";
import Checkbox from "../../../../components/Input/Checkbox";

import Request from "../../../../services/request";
import { usersRouteApi, profilesRouteApi } from "../../../../routes/config/api";

import { IconButton } from "./styles";

interface FormProps {
  isShowPage?: boolean;
  formRef: React.RefObject<FormHandles>;
  onSubmit?: (data: any) => void;
}

const EndAdornment = ({ show, onClick }: any) => {
  if (show) {
    return (
      <IconButton onClick={onClick}>
        <MdRemoveRedEye color="#aaa" size={22} />
      </IconButton>
    );
  }

  return (
    <IconButton onClick={onClick}>
      <IoMdEyeOff color="#aaa" size={22} />
    </IconButton>
  );
};

const FormComponent: React.FC<FormProps> = ({
  formRef,
  isShowPage = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(
    false
  );
  const [profiles, setProfiles] = useState([]);
  const { id } = useParams<any>();

  const getHelperText = useCallback(() => {
    return isShowPage ? "" : "Campo Obrigatório";
  }, [isShowPage]);

  useEffect(() => {
    async function findUser(): Promise<void> {
      if (id) {
        try {
          setLoading(true);

          const data = await Request.get(`${usersRouteApi.path}/${id}`);
          if (!!data) {
            formRef.current?.setData(data);

            if (!!data.user_profiles?.length) {
              const formattedUserProfiles = data.user_profiles.map(
                (profile: any) => ({
                  label: profile.name,
                  value: profile.id,
                })
              );
              formRef.current?.setFieldValue('profiles', formattedUserProfiles);
            }
          }
        } catch (err) {
          toast.error("Ops, não foi possível carregar as informações");
        } finally {
          setLoading(false);
        }
      }

      if (!isShowPage) {
        const profilesData = await Request.get(
          `${profilesRouteApi.path}?per_page=10000&sort=name&direction=asc`
        );
        if (!!profilesData.data) {
          const formattedProfiles = profilesData.data.map((module: any) => ({
            label: module.name,
            value: module.id,
          }));
          setProfiles(formattedProfiles);
        }
      }
    }

    findUser();
  }, [formRef, id, isShowPage]);

  return (
    <Form formRef={formRef} loading={loading} disabledForm={isShowPage}>
      <InputText
        name="name"
        label="Nome"
        helperText={getHelperText()}
        autoComplete="off"
      />
      <InputText
        name="username"
        label="Usuário"
        type="text"
        autoComplete="off"
        helperText={getHelperText()}
        sm={6}
        md={6}
      />
      <InputText
        name="email"
        label="E-mail"
        type="text"
        autoComplete="off"
        sm={6}
        md={6}
      />
      <InputText
        name="powerbi_user"
        label="Usuário no Power BI"
        helperText={getHelperText()}
        autoComplete="off"
        sm={6}
        md={6}
      />
      <InputSelect
        isMulti
        name="profiles"
        label="Selecione um perfil"
        options={profiles}
        closeMenuOnSelect={false}
        helperText={getHelperText()}
        sm={6}
        md={6}
      />

      {!isShowPage && (
        <>
          <InputText
            name="password"
            label="Senha"
            type={showPassword ? "text" : "password"}
            helperText={getHelperText()}
            sm={6}
            md={6}
            endAdornment={
              <EndAdornment
                show={showPassword}
                onClick={() => setShowPassword(!showPassword)}
              />
            }
          />
          <InputText
            name="password_confirmation"
            label="Senha de confirmação"
            type={showPasswordConfirmation ? "text" : "password"}
            helperText={getHelperText()}
            autoComplete="off"
            sm={6}
            md={6}
            endAdornment={
              <EndAdornment
                show={showPasswordConfirmation}
                onClick={() =>
                  setShowPasswordConfirmation(!showPasswordConfirmation)
                }
              />
            }
          />
        </>
      )}

      <Checkbox name="is_admin" label="Usuário será Administrador" />
    </Form>
  );
};

export default FormComponent;
