import React, { memo } from "react";
import { Link } from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import { MdVisibility, MdEdit, MdDelete } from "react-icons/md";

import { IconButton } from "./styles";

type Actions = "show" | "edit" | "delete";

export interface ActionsProps {
  actions: Actions[];
  paths: {
    show: string;
    edit?: string;
    delete?: string;
  };
  onDelete?: (deletePath: string) => void;
}

const Actions: React.FC<ActionsProps> = ({ actions, paths, onDelete }) => {
  return (
    <>
      {actions.includes("show") && (
        <Link to={paths.show}>
          <Tooltip title="Visualizar">
            <IconButton>
              <MdVisibility fontSize="25" color="#6CBD92" />
            </IconButton>
          </Tooltip>
        </Link>
      )}

      {actions.includes("edit") && (
        <Link to={paths.edit}>
          <Tooltip title="Editar">
            <IconButton>
              <MdEdit fontSize="25" color="#F49639" />
            </IconButton>
          </Tooltip>
        </Link>
      )}

      {actions.includes("delete") && (
        <Tooltip title="Excluir">
          <IconButton onClick={() => onDelete && onDelete(paths.delete || "")}>
            <MdDelete fontSize="25" color="#eb5f4a" />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
};

export default memo(Actions);
