import styled from "styled-components";
import { Table, PagingPanel } from "@devexpress/dx-react-grid-material-ui";
import MUITableHead from "@material-ui/core/TableHead";
import MUIPaper from "@material-ui/core/Paper";

export const Container = styled.div`
  display: block;
  width: calc(100vw - 60px);
  transition: width 400ms ease-in-out;
  scrollbar-width: thin;

  *::-webkit-scrollbar {
    width: 1.4em;
    height: 7px;
    background: #ddd;
  }
  *::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 6px;
  }
`;

export const Paper = styled(MUIPaper)`
  position: relative;
  background-color: ${(props) => props.theme.colors.white} !important;
  -webkit-box-shadow: 0px 3px 15px 0px #70707029 !important;
  -moz-box-shadow: 0px 3px 15px 0px #70707029 !important;
  box-shadow: 0px 3px 15px 0px #70707029 !important;
`;

export const TableHead = styled(MUITableHead)`
  tr {
    th {
      color: ${(props) => props.theme.colors.text};
      font-size: 0.8rem;
      font-weight: bold;
      border-bottom: 1px solid #ddd;
      background-color: ${(props) => props.theme.colors.white};
      & .MuiTableSortLabel-root.MuiTableSortLabel-active {
        color: ${(props) => props.theme.colors.primary} !important;
      }
      span {
        &:hover {
          color: ${(props) => props.theme.colors.primary};
        }
        &:focus {
          color: ${(props) => props.theme.colors.primary};
        }
        svg {
          color: ${(props) => props.theme.colors.primary} !important;
        }
      }
      :first-child {
        border-top-left-radius: 3px;
      }
      :last-child {
        border: none;
        border-left: 1px solid #ddd;
        border-bottom: 1px solid #ddd;
        border-top-right-radius: 3px;
      }
      ::after {
        background-color: ${(props) => props.theme.colors.primary};
        opacity: 0.2;
        border: ${(props) => props.theme.colors.text} solid 1px;
        border-bottom: none;
      }
    }
    :last-child {
      display: block;
    }
  }
`;

export const TableCell = styled(Table.Cell)`
  background-color: ${(props) => props.theme.colors.white} !important;
  color: ${(props) => props.theme.colors.text} !important;
  border-bottom: 1px solid #ddd !important;

  :last-child {
    border-left: 1px solid #ddd;
    border-bottom: 1px solid #ddd !important;
  }
`;

export const PagingPanelContainer = styled(PagingPanel.Container)`
  background-color: ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.text};
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  font-size: 0.8rem !important;

  div {
    color: ${(props) => props.theme.colors.text};

    svg {
      color: ${(props) => props.theme.colors.text};
    }

    button {
      span {
        color: ${(props) => props.theme.colors.text} !important;
      }
    }

    & .Mui-disabled {
      span {
        svg {
          color: #bbb;
        }
      }
    }

    & [class^="PageSizeSelector-label"],
    [class*=" PageSizeSelector-label"] {
      font-size: 0.75rem !important;
    }

    & [class^="PageSizeSelector-inputRoot"],
    [class*="PageSizeSelector-inputRoot"] {
      font-size: 0.75rem !important;

      & .MuiSelect-select.MuiSelect-select {
        padding-right: 30px;
        padding-top: 9px;
      }
    }

    & [class^="Pagination-activeButton"],
    [class*=" Pagination-activeButton"] {
      span {
        color: ${(props) => props.theme.colors.primary} !important;
      }
    }
  }
`;
