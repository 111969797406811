import React from "react";
import { Switch, Route } from "react-router-dom";

import LinearProgress from "../components/LinearProgress";

import {
  DefaultRouteComponent,
  SignInRouteComponent,
  ModulesRouteComponent,
  UsersRouteComponent,
  ProfilesRouteComponent,
  UserProfileRouteComponent,
  TasksRouteComponent,
  HelpRouteComponent,
  signInRoute,
  listModulesRoute,
  listUsersRoute,
  listProfilesRoute,
  updateProfileRoute,
  listTasksRoute,
  helpRoute,
} from "./config";
import { useGlobalLoading } from "../hooks/GlobalLoadingContext";
import { BreadcrumbsProvider } from "../hooks/BreadcrumbsContext";
import { useAuth } from "../hooks/AuthContext";
import { useProfileModules } from "../hooks/ProfileModulesContext";

import Header from "../components/Header";
import GlobalLayout from "../components/Layouts/Global";
import BIDashboard from "../pages/BIDashboard";
import RouteWrapper from "./RouteWrapper";

import { Main, ContainerLoading, AnimatedLoading } from "./styles";

const DefaultRoutes: React.FC = () => (
  <>
    <Route path={signInRoute.path} component={SignInRouteComponent} exact />
    <Route>
      <DefaultRouteComponent />
    </Route>
  </>
);

const Routes: React.FC = () => {
  const { signed, user: { is_admin } } = useAuth();
  const { loading } = useGlobalLoading();
  const { loading: profileModulesLoading, modulesPath } = useProfileModules();

  if (!signed) {
    return (
      <>
        <LinearProgress loading={loading} />
        <Switch>
          <DefaultRoutes />
        </Switch>
      </>
    );
  }

  if (profileModulesLoading) {
    return (
      <ContainerLoading>
        <AnimatedLoading />
      </ContainerLoading>
    );
  }

  return (
    <Main>
      <Switch>
        {modulesPath.map((modulePath, key) => (
          <RouteWrapper
            isPrivate
            exact
            key={`${modulePath}/${key}`}
            path={modulePath}
            component={BIDashboard}
          />
        ))}

        <BreadcrumbsProvider>
          <LinearProgress loading={loading} />
          <Header />
          <GlobalLayout>
            <Switch>
              {is_admin && [
                <Route key={listModulesRoute.path} path={listModulesRoute.path}>
                  <ModulesRouteComponent />
                </Route>,
                <Route key={listUsersRoute.path} path={listUsersRoute.path}>
                  <UsersRouteComponent />
                </Route>,
                <Route key={listProfilesRoute.path} path={listProfilesRoute.path}>
                  <ProfilesRouteComponent />
                </Route>,
                <Route key={listTasksRoute.path} path={listTasksRoute.path}>
                  <TasksRouteComponent />
                </Route>,
              ]}

              <Route path={updateProfileRoute.path} exact>
                <UserProfileRouteComponent />
              </Route>
              <Route path={helpRoute.path}>
                <HelpRouteComponent />
              </Route>

              <DefaultRoutes />
            </Switch>
          </GlobalLayout>
        </BreadcrumbsProvider>
      </Switch>
    </Main>
  );
};

export default Routes;
