import styled from "styled-components";
import MUIBreadcrumbs from "@material-ui/core/Breadcrumbs";
import MUITypography from "@material-ui/core/Typography";
import MUILink from "@material-ui/core/Link";

export const Typography = styled(MUITypography)`
  color: ${(props) => props.theme.colors.primary};
  font-size: 0.85rem !important;
  font-weight: 600 !important;
`;

export const Link = styled(MUILink)`
  color: ${(props) => props.theme.colors.text} !important;
  font-size: 0.75rem;
  font-weight: 500 !important;
`;

export const Breadcrumbs = styled(MUIBreadcrumbs)`
  margin-left: 30px !important;
  ol {
    & .MuiBreadcrumbs-separator {
      color: ${(props) => props.theme.colors.text};
    }
  }
`;
