import React, { useState } from "react";
import ListItemText from "@material-ui/core/ListItemText";
import { MdEdit, MdDelete, MdArrowDropDown } from "react-icons/md";
import Button from "@material-ui/core/Button";

import {
  Container,
  Link,
  OptionsButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from "./styles";

export interface NavMenuProps {
  StartComponent?: React.FC<any>;
  newRoute?: string;
  editRoute?: string;
  optionsButton?: {
    visible: boolean;
    deleteRoute?: string;
  };
}

const NavMenu: React.FC<NavMenuProps> = ({
  StartComponent,
  newRoute,
  editRoute,
  optionsButton,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  function handleOpenMenuOptions(event: any) {
    setAnchorEl(event.currentTarget);
  }

  function handleCloseMenuOptions() {
    setAnchorEl(null);
  }

  return (
    <Container>
      {StartComponent && <StartComponent />}

      {optionsButton?.visible && (
        <OptionsButton
          aria-controls="customized-menu"
          aria-haspopup="true"
          variant="contained"
          color="inherit"
          onClick={handleOpenMenuOptions}
          endIcon={<MdDelete size={24} />}
        >
          Opções
        </OptionsButton>
      )}
      <Menu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenuOptions}
      >
        {optionsButton?.deleteRoute && (
          <MenuItem onClick={() => {}}>
            <ListItemIcon>
              <MdArrowDropDown fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Excluir" />
          </MenuItem>
        )}
      </Menu>

      {!!editRoute && (
        <Link to={editRoute}>
          <Button
            startIcon={<MdEdit style={{ fontSize: 18, marginBottom: 3 }} />}
            variant="contained"
            style={{
              backgroundColor: "#fd951f",
              color: "#fff",
              marginRight: 15,
              padding: "5px 20px",
              height: 38,
            }}
          >
            <span
              style={{
                fontWeight: 600,
                textTransform: "none",
                fontSize: "0.8rem",
              }}
            >
              Editar
            </span>
          </Button>
        </Link>
      )}

      {!!newRoute && (
        <Link to={newRoute}>
          <Button
            variant="contained"
            style={{
              backgroundColor: "#6CBD92",
              color: "#fff",
              padding: "5px 20px",
              height: 38,
            }}
          >
            <span
              style={{
                fontWeight: 600,
                textTransform: "none",
                fontSize: "0.8rem",
              }}
            >
              Novo registro
            </span>
          </Button>
        </Link>
      )}
    </Container>
  );
};

export default NavMenu;
