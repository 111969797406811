import React, { useRef, useCallback } from "react";
import { FormHandles } from "@unform/core";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import LayoutCreate from "../../components/Layouts/Create";
import Form from "./components/Form";

import Request, { HTTP_STATUS } from "../../services/request";
import { listModulesRoute, showModuleRoute } from "../../routes/config";
import { modulesRouteApi } from "../../routes/config/api";
import getValidationsErrors from "../../utils/errors/getValidationsErrors";
import { useGlobalLoading } from "../../hooks/GlobalLoadingContext";

export const Create: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const { loading, setLoading } = useGlobalLoading();

  const createModule = useCallback(
    async (data: any) => {
      try {
        setLoading(true);

        const formattedData = {
          name: data.name,
          group_id: data.group_id,
          report_id: data.report_id,
          dataset_id: data.dataset_id,
          route: data.route,
          file: data.image || undefined,
          permissions: data.permissions?.value,
          filter_pane_enabled: data.filter_pane_enabled,
          nav_content_pane_enabled: data.nav_content_pane_enabled,
        };
        const formData = new FormData();

        Object.entries(formattedData).forEach((data) => {
          const [key, value] = data;
          formData.append(key, value);
        });

        const response = await Request.post(modulesRouteApi.path, formData);

        toast.success("Módulo criado com sucesso!");
        history.push(showModuleRoute.build({ id: response.id }));
      } catch (err) {
        const statusCode = err.response?.data?.statusCode;
        if (statusCode === HTTP_STATUS.UNPROCESSABLE_ENTITY) {
          const errMessage = err.response.data.message;

          if (errMessage?.route) {
            formRef.current?.setErrors(errMessage);
          }
        } else {
          toast.error("Ops, não foi possível cadastrar este módulo!");
        }
      } finally {
        setLoading(false);
      }
    },
    [history, setLoading]
  );

  const handleCreate = useCallback(async () => {
    try {
      formRef.current?.setErrors({});

      const data = formRef.current?.getData() as any;
      const schema = Yup.object().shape({
        name: Yup.string().required("Campo Obrigatório"),
        group_id: Yup.string().required("Campo Obrigatório"),
        report_id: Yup.string().required("Campo Obrigatório"),
        dataset_id: Yup.string().required("Campo Obrigatório"),
        route: Yup.string().required("Campo Obrigatório"),
        permissions: Yup.number().required("Campo Obrigatório"),
      });

      await schema.validate(
        { ...data, permissions: data.permissions?.value },
        { abortEarly: false }
      );

      createModule(data);
    } catch (err) {
      const validationErrors = getValidationsErrors(err);
      formRef.current?.setErrors(validationErrors);
    }
  }, [createModule]);

  return (
    <LayoutCreate
      breadcrumbs={[
        { text: "Módulos", path: listModulesRoute.path },
        { text: "Adicionar Módulo" },
      ]}
      footerActionsProps={{
        onSubmit: handleCreate,
        onCancelRoute: listModulesRoute.path,
        loading,
      }}
    >
      <Form formRef={formRef} />
    </LayoutCreate>
  );
};
