import styled from "styled-components";

interface IStatus {
  isInactive: "true" | "false";
}

export const Status = styled.div<IStatus>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 28px;
  border-radius: 4px;
  border: 1px solid
    ${(props) =>
      props.isInactive === "true"
        ? props.theme.colors.red
        : props.theme.colors.green};
  color: ${(props) =>
    props.isInactive === "true"
      ? props.theme.colors.red
      : props.theme.colors.green};
  font-size: 0.7rem;
`;
