import styled from "styled-components";

import logo from "../assets/images/logo.png";

export const Main = styled.main`
  display: flex;
  flex: 1;
  overflow-x: hidden;
`;

export const ContainerLoading = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.theme.colors.secundary};
`;

export const AnimatedLoading = styled.img.attrs({
  src: logo,
})`
  background-repeat: no-repeat;
  background-position-x: 50%;
  background-position-y: 50%;
  width: 250px;
  object-fit: contain;

  transform: scale(1);
  animation: pulse 1300ms infinite;

  @keyframes pulse {
    0% {
      transform: scale(0.85);
    }

    70% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.85);
    }
  }
`;
