import React, { useRef } from "react";
import { FormHandles } from "@unform/core";
import { useParams } from "react-router-dom";

import LayoutShow from "../../components/Layouts/Show";
import Form from "./components/Form";

import {
  createProfileRoute,
  editProfileRoute,
  listProfilesRoute,
} from "../../routes/config";

export const Show: React.FC = () => {
  const { id } = useParams<any>();
  const formRef = useRef<FormHandles>(null);

  return (
    <LayoutShow
      breadcrumbs={[
        { text: "Perfis", path: listProfilesRoute.path },
        { text: "Visualizar Perfil" },
      ]}
      navMenuProps={{
        newRoute: createProfileRoute.path,
        editRoute: editProfileRoute.build({ id }),
      }}
    >
      <Form formRef={formRef} isShowPage />
    </LayoutShow>
  );
};
