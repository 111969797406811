import Route from "../../services/route";

const rootPath = "/api/v1";

export const modulesRouteApi = new Route(`${rootPath}/modules`);

export const usersRouteApi = new Route(`${rootPath}/users`);

export const profilesRouteApi = new Route(`${rootPath}/profiles`);

export const profileModulesRouteApi = new Route(`${rootPath}/profile-modules`);

export const powerbiRouteApi = new Route(`${rootPath}/powerbi`);

export const taskSchedulerRouteApi = new Route(`${rootPath}/task-scheduler`);

export const userProfilesRouteApi = new Route(`${rootPath}/user-profiles`);

export const favoriteModulesRouteApi = new Route(`${rootPath}/favorite-modules`);

export const updateUserInfoRouteApi = new Route(`${rootPath}/update-user-info`);
