import React from "react";

import BaseLayout from "./components/BaseLayout";

import { helpRoute } from "../../routes/config"

import dashboardAvailablesImg from '../../assets/images/available-dashboards-1.png';

import { Container } from "./styles";

const AvailableDashboards: React.FC = () => {
  return (
    <BaseLayout
      breadcrumbs={[
        { text: "Ajuda", path: helpRoute.path },
        { text: "Página Inicial - Dashboards Disponíveis" },
      ]}
    >
      <Container>
        <div className="available-dashboards">
          <img src={dashboardAvailablesImg} alt="" />
          <span>
            Os <strong>Dashboards Disponíveis</strong> apresentarão todos os dashboards liberados conforme o seu perfil de acesso.&nbsp;
            Estes sempre estarão visíveis do menor para o maior ID.
          </span>
        </div>
      </Container>
    </BaseLayout>
  );
};

export { AvailableDashboards };
