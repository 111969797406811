import React, { useState, createContext, useContext } from "react";

export interface GlobalLoadingContextProps {
  loading: boolean;
  setLoading(loading: boolean): void;
}

const GlobalLoadingContext = createContext<GlobalLoadingContextProps>(
  {} as GlobalLoadingContextProps
);

export const GlobalLoadingProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);

  return (
    <GlobalLoadingContext.Provider value={{ loading, setLoading }}>
      {children}
    </GlobalLoadingContext.Provider>
  );
};

export const useGlobalLoading = (): GlobalLoadingContextProps => {
  const context = useContext(GlobalLoadingContext);

  if (!context) {
    throw new Error(
      "useGlobalLoading must be used within a GlobalLoadingProvider"
    );
  }

  return context;
};

export default GlobalLoadingContext;
