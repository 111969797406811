import React, { useState, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { MdDesktopWindows } from "react-icons/md";
import { GoDeviceMobile } from "react-icons/go";
import { PowerBIEmbed } from "powerbi-client-react";
import {
  IEmbedConfiguration,
  IQnaEmbedConfiguration,
  IVisualEmbedConfiguration,
  models,
} from "powerbi-client";

import { dashboardRoute } from "../../routes/config";
import Request from "../../services/request";
import getDeviceType from "../../utils/getDeviceType";
import { powerbiRouteApi } from "../../routes/config/api";

import { Container, FAB, Error } from "./styles";

type IEmbedConfig =
  | IEmbedConfiguration
  | IQnaEmbedConfiguration
  | IVisualEmbedConfiguration;

interface IResponse {
  id: string;
  accessToken: string;
  embedUrl: string;
  filterPaneEnabled: boolean;
  navContentPaneEnabled: boolean;
  permissions: models.Permissions;
}

const generateKey = () => Math.random().toString();
const defaultEmbedConfig: IEmbedConfig = {
  id: "",
  type: "report",
  accessToken: "",
  embedUrl: "",
  tokenType: 1, // Embed
  permissions: 0, // Read
  settings: {
    filterPaneEnabled: false,
    navContentPaneEnabled: false,
    layoutType: 0, // Master
  },
};

let isDragging = false;

function dragElement(id: string) {
  let element = document.getElementById(id) as any;

  function dragMouseDown(e: any) {
    e = e || window.event;
    e.preventDefault();

    document.onmouseup = closeDragElement;
    document.onmousemove = elementDrag;
  }

  function elementDrag(e: any) {
    e = e || window.event;
    e.preventDefault();

    isDragging = true;

    element.style.bottom = `${window.innerHeight - e.clientY - 30}px`;
    element.style.right = `${window.innerWidth - e.clientX - 28}px`;
  }

  function closeDragElement() {
    document.onmouseup = null;
    document.onmousemove = null;

    setTimeout(() => {
      isDragging = false;
    }, 500);
  }

  if (element) {
    element.onmousedown = dragMouseDown;
  }
}

const BIDashboard: React.FC = () => {
  const [embedConfig, setEmbedConfig] = useState<IEmbedConfig>(
    defaultEmbedConfig
  );
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [isMobile, setIsMobile] = useState(() => {
    const deviceType = getDeviceType();
    return deviceType !== "desktop";
  });
  const [containerKey, setContainerKey] = useState(generateKey());
  const {
    location: { pathname },
    push: locationPush,
  } = useHistory();

  const findPowerBIInfo = useCallback(
    async (moduleId: number) => {
      try {
        setLoading(true);
        setError(false);

        const response = (await Request.get(
          `${powerbiRouteApi.path}/${moduleId}`
        )) as IResponse;

        if (!!response) {
          setEmbedConfig((oldData: any) => {
            const {
              filterPaneEnabled,
              navContentPaneEnabled,
              ...restResponse
            } = response;

            const desktopMode = 0;
            const mobilePortraitMode = 2;
            const settings = {
              ...oldData.settings,
              filterPaneEnabled,
              navContentPaneEnabled,
              layoutType: isMobile ? mobilePortraitMode : desktopMode,
            };

            return { ...oldData, ...restResponse, settings };
          });
          setContainerKey(generateKey());
        }
      } catch (err) {
        setError(true);
      } finally {
        setTimeout(() => setLoading(false), 5000);
      }
    },
    [isMobile]
  );

  const handleClick = useCallback(async () => {
    const moduleId = pathname.split("/").pop();

    if (moduleId) {
      findPowerBIInfo(parseInt(moduleId as any));
    } else {
      setError(true);
    }
  }, [findPowerBIInfo, pathname]);

  const handleChangeDeviceType = useCallback(async () => {
    if (!isDragging) {
      setContainerKey(generateKey());
      setIsMobile((oldState) => !oldState);
    }
  }, []);

  useEffect(() => {
    const moduleId = pathname.split("/").pop();

    if (moduleId) {
      findPowerBIInfo(parseInt(moduleId as any));
    } else {
      alert("Acesso não permitido, contate o Administrador!");
      locationPush(dashboardRoute.path);
    }
  }, [findPowerBIInfo, locationPush, pathname]);

  useEffect(() => {
    dragElement("fab-device-type");
  }, []);

  if (error) {
    return (
      <Error>
        <h3>Ops, aconteceu algum problema ao buscar as informações</h3>
        <span>Qualquer dúvida, contate o Administrador</span>

        <button onClick={handleClick}>Recarregar página</button>
      </Error>
    );
  }

  return (
    <>
      <FAB
        id="fab-device-type"
        disabled={loading}
        onClick={handleChangeDeviceType}
      >
        {isMobile ? (
          <MdDesktopWindows size={20} />
        ) : (
          <GoDeviceMobile size={20} />
        )}
      </FAB>
      <Container key={containerKey}>
        <PowerBIEmbed
          embedConfig={embedConfig}
          cssClassName={"powerbi-report-embed"}
        />
      </Container>
    </>
  );
};

export default BIDashboard;
