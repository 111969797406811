import React from "react";

import BaseLayout from "./components/BaseLayout";

import { helpRoute } from "../../routes/config";

import lastAccessesImg from '../../assets/images/last-accesses-1.png';

import { Container } from "./styles";

const LastAccesses: React.FC = () => {
  return (
    <BaseLayout
      breadcrumbs={[
        { text: "Ajuda", path: helpRoute.path },
        { text: "Timeline dos Últimos Acessos" },
      ]}
    >
      <Container>
        <div className="last-accesses">
          <img src={lastAccessesImg} alt="" />
          <span>Na parte inferior da página contém os últimos acessos, onde apresentará os últimos dashboards acessados por você dentro do Portal BI.</span>
        </div>
      </Container>
    </BaseLayout>
  );
};

export { LastAccesses };
