import React, { useState, useEffect } from "react";
import { FormHandles } from "@unform/core";
import { MdRemoveRedEye } from "react-icons/md";
import { IoMdEyeOff } from "react-icons/io";

import Form from "../../../../components/Form";
import InputText from "../../../../components/Input/Text";

import { useAuth } from "../../../../hooks/AuthContext";

import { IconButton } from "./styles";

interface FormProps {
  formRef: React.RefObject<FormHandles>;
}

const EndAdornment = ({ show, onClick }: any) => {
  if (show) {
    return (
      <IconButton onClick={onClick}>
        <MdRemoveRedEye color="#aaa" size={22} />
      </IconButton>
    );
  }

  return (
    <IconButton onClick={onClick}>
      <IoMdEyeOff color="#aaa" size={22} />
    </IconButton>
  );
};

const FormComponent: React.FC<FormProps> = ({ formRef }) => {
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] = useState(
    false
  );
  const { user } = useAuth();

  useEffect(() => {
    formRef.current?.setData(user);
  }, [formRef, user]);

  return (
    <Form formRef={formRef}>
      <InputText
        name="name"
        label="Nome"
        helperText={"Campo Obrigatório"}
        autoComplete="off"
      />

      <InputText
        name="password"
        label="Nova Senha"
        type={showPassword ? "text" : "password"}
        helperText={"Campo Obrigatório"}
        sm={6}
        md={6}
        endAdornment={
          <EndAdornment
            show={showPassword}
            onClick={() => setShowPassword(!showPassword)}
          />
        }
      />
      <InputText
        name="password_confirmation"
        label="Senha de confirmação"
        type={showPasswordConfirmation ? "text" : "password"}
        helperText={"Campo Obrigatório"}
        autoComplete="off"
        sm={6}
        md={6}
        endAdornment={
          <EndAdornment
            show={showPasswordConfirmation}
            onClick={() =>
              setShowPasswordConfirmation(!showPasswordConfirmation)
            }
          />
        }
      />
    </Form>
  );
};

export default FormComponent;
