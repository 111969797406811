import React, {
  useState,
  useCallback,
  useEffect,
  createContext,
  useContext,
} from "react";

import Route from "../services/route";

interface Params {
  page: number;
  per_page: number;
  sort: string;
  direction: "asc" | "desc";
  total: number;
  searchValue: string;
}

interface SetParams {
  page?: number;
  per_page?: number;
  sort?: string;
  direction?: "asc" | "desc";
  total?: number;
  searchValue?: string;
}

interface QueryParamsContextProps {
  params: Params;
  setParams(params: SetParams): void;
  getPath(route: Route): string;
}

const QueryParamsContext = createContext({} as QueryParamsContextProps);
const initialParams: Params = {
  page: 0,
  per_page: 5,
  sort: "id",
  direction: "desc",
  total: 0,
  searchValue: '',
};

const currentQueryParams = initialParams;

export const QueryParamsProvider: React.FC = ({ children }) => {
  const [params, setQueryParams] = useState(initialParams);

  const setParams = useCallback((newParams: SetParams) => {
    const currentParams = { ...currentQueryParams };

    Object.assign(currentParams, newParams);

    setQueryParams(currentParams);
  }, []);

  const getPath = useCallback(
    (routeIns: Route) => {
      const hasUrlParams = Object.keys(params).length;
      const hasParamsIncluded = Object.keys(routeIns.queryParams).length;

      // When screen refresh params keys are not included on route instance
      if (hasUrlParams && !hasParamsIncluded) {
        routeIns.queryParams = Object.keys(params);
      }

      return routeIns.build(params);
    },
    [params]
  );

  useEffect(() => {
    Object.assign(currentQueryParams, { ...params });
  }, [params]);

  return (
    <QueryParamsContext.Provider value={{ params, setParams, getPath }}>
      {children}
    </QueryParamsContext.Provider>
  );
};

export const useQueryParams = (): QueryParamsContextProps => {
  const context = useContext(QueryParamsContext);

  if (!context) {
    throw new Error("useQueryParams must be used within a QueryParamsProvider");
  }

  return context;
};

export default QueryParamsContext;
