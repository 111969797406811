import React from "react";

import Toolbar from "../../Toolbar";

import { Section, Content } from "./styles";

const GlobalLayout: React.FC = ({ children }) => (
  <Section>
    <Toolbar />
    <Content>{children}</Content>
  </Section>
);

export default GlobalLayout;
