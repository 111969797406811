import styled, { css } from "styled-components";
import MUIAppBar from "@material-ui/core/AppBar";

interface ContainerProps {
  name?: string;
}

export const Container = styled.div<ContainerProps>`
  display: flex;

  .logo {
    display: flex;
    align-items: center;
    padding-right: 16px;
    color: ${props => props.theme.colors.white};
    text-decoration: none;

    .bi-logo {
      max-height: 40px;
    }

    span {
      padding-left: 10px;
      font-weight: bold;
    }

    @media (max-width: 800px) {
      padding-right: 0;

      span {
        display: none;
      }
    }
  }

  .search-container {
    display: flex;
    flex: 1;
    justify-content: center;
  }

  .schumann-logo {
    margin-left: 16px;

    @media (max-width: 800px) {
      display: none;
    }
  }
`;

export const Root = styled.div`
  display: flex;
`;

export const AppBar = styled(MUIAppBar)`
  z-index: 1500 !important;

  & .MuiToolbar-root {
    background-color: ${(props) => props.theme.colors.primary};
    color: ${(props) => props.theme.colors.white};
  }

  @media (max-width: 800px) {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  }
`;

interface IAvatarContainerProps {
  popoverOpen: number;
}

export const AvatarContainer = styled.div<IAvatarContainerProps>`
  display: flex;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  height: 50px;

  a {
    display: flex;
    flex-direction: row;
    text-decoration: none;
    color: ${(props) => props.theme.colors.white};
    cursor: pointer;
    ${(props): any =>
      !!props.popoverOpen &&
      css`
        &::before {
          content: '';
          position: absolute;
          bottom: -5px;
          left: calc(100% - 20px);
          transform: translateX(-50%);
          width: 0px;
          height: 0px;
          border-style: solid;
          border-width: 0px 7.5px 8px;
          border-color: transparent transparent ${(props) =>
            props.theme.colors.white};
        }
      )
      `}
  }

  @media (max-width: 600px) {
    flex: none;
  }
`;

export const PopoverContent = styled.div`
  width: 200px;
  padding: 10px 0px;

  a {
    display: flex;
    flex-direction: row;
    align-items: center;
    text-decoration: none;
    color: ${(props) => props.theme.colors.text};
    cursor: pointer;
    padding: 10px 20px;

    svg {
      color: ${(props) => props.theme.colors.primary};
    }

    span {
      padding-left: 10px;
      font-size: 0.75rem;
    }

    &:hover {
      background-color: #eee;
    }
  }
`;
