import React, { useState, useCallback } from 'react';
import { CgExport } from "react-icons/cg";
import Button from "@material-ui/core/Button";
import { transparentize } from "polished";

import Request from "../../services/request";

interface IExportXML {
  filename: string;
  fetchUrl: string;
  headers: string[];
  columns: string[];
  formatData?: (data: any) => any[];
}

const ExportToXML: React.FC<IExportXML> = ({
  filename,
  headers,
  columns,
  fetchUrl,
  formatData
}) => {
  const [loading, setLoading] = useState(false);

  const downloadFile = useCallback((data) => {
    const element = window.document.createElement('a');
    const blobData = new Blob(data, { type: 'text/csv;charset=utf-8' });

    element.href = window.URL.createObjectURL(blobData);
    element.download = filename;
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }, [filename]);

  const parseDataToFileFormat = useCallback((data) => {
    const formattedData = formatData ? formatData(data) : data;
    const parsedData = formattedData.map((dt: any) => {
      const mergedData = Object
        .entries(dt)
        .filter((d: any) => {
          const [key] = d;
          return columns.includes(key);
        })
        .map((d: any) => {
          const [, value] = d;
          return value;
        })
        .join(',');

      return mergedData;
    });
    const csvHeader = '\uFEFF';
    const formattedHeaders = `${csvHeader}${headers.join(',').concat("\n")}`;
    const fileContent = formattedHeaders.concat(parsedData.join("\n"))

    return [fileContent];
  }, [columns, formatData, headers]);

  const handleExport = useCallback(async () => {
    try {
      setLoading(true);

      const data = await Request.get(fetchUrl);
      const parsedData = parseDataToFileFormat(data);

      downloadFile(parsedData);
    } finally {
      setLoading(false);
    }
  }, [downloadFile, fetchUrl, parseDataToFileFormat]);

  return (
    <Button
      disabled={loading}
      startIcon={<CgExport style={{ fontSize: 18, marginBottom: 2 }} />}
      variant="contained"
      style={{
        backgroundColor: loading ? transparentize(0.7, "#fd951f") : "#fd951f",
        color: "#fff",
        marginRight: 15,
        padding: "5px 20px",
        height: 38,
      }}
      onClick={handleExport}
    >
      <span
        style={{
          fontWeight: 600,
          textTransform: "none",
          fontSize: "0.8rem",
        }}
      >
        Exportar dados
      </span>
    </Button>
  );
}

export default ExportToXML;
