import React from "react";

import Route from "../../services/route";
import RouteWrapper from "../RouteWrapper";
import UserProfile from "../../pages/UserProfile";

export const updateProfileRoute = new Route("/update-profile");

export const UserProfileRouteComponent: React.FC = () => (
  <RouteWrapper
    exact
    isPrivate
    path={updateProfileRoute.path}
    component={UserProfile}
  />
);
