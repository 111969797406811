import React from "react";
import { parseISO, format } from "date-fns";

import LayoutList from "../../components/Layouts/List";

import { taskSchedulerRouteApi } from "../../routes/config/api";
import { createTaskRoute, showTaskRoute } from "../../routes/config";
import { formatWeekDays } from './utils/formatWeekDays';

const List: React.FC = () => {
  return (
    <LayoutList
      breadcrumbs={[{ text: "Tarefas" }]}
      navMenuProps={{
        newRoute: createTaskRoute.path,
      }}
      tableProps={{
        requestOptions: {
          url: taskSchedulerRouteApi,
        },
        columns: [
          { name: "name", title: "Nome" },
          { name: "days", title: "Dias" },
          { name: "start_date", title: "Data de Início" },
          { name: "end_date", title: "Data Final" },
          { name: "hour", title: "Horário" },
          { name: "actions", title: "Ações" },
        ],
        columnsProperties: [
          { columnName: "days", width: 225 },
          { columnName: "end_date", width: 140 },
          { columnName: "start_date", width: 140 },
          { columnName: "hour", width: 100 },
          { columnName: "actions", width: 150, align: "center" },
        ],
        customActions: ({ id }) => ({
          actions: ["show", "delete"],
          paths: {
            show: showTaskRoute.build({ id }),
            delete: `${taskSchedulerRouteApi.path}/${id}`,
          },
        }),
        dataTypeProvider: [
          {
            columnName: "days",
            formatterComponent: ({ row }) => (
              <div style={{ fontSize: "0.75rem" }}>{formatWeekDays(row.days)}</div>
            ),
          },
          {
            columnName: "start_date",
            formatterComponent: ({ row }) => {
              const parsedDate = parseISO(row.start_date);
              const formatDate = format(parsedDate, 'dd/MM/yyyy');

              return (
                <div style={{ fontSize: "0.75rem" }}>{formatDate}</div>
              );
            },
          },
          {
            columnName: "end_date",
            formatterComponent: ({ row }) => {
              const parsedDate = parseISO(row.end_date);
              const formatDate = format(parsedDate, 'dd/MM/yyyy');

              return (
                <div style={{ fontSize: "0.75rem" }}>{formatDate}</div>
              );
            },
          },
        ],
      }}
    />
  );
};

export { List };
