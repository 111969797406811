import React, { useCallback, forwardRef, memo } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import Button from "@material-ui/core/Button";

interface ISupportModal {
  isOpen: boolean;
  closeModal(): void;
}

const Transition = forwardRef(function Transition(props: any, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
}) as any;

const SupportModal: React.FC<ISupportModal> = ({ isOpen, closeModal }) => {
  const handleClose = useCallback(() => {
    closeModal();
  }, [closeModal]);

  return (
    <Dialog
      open={isOpen}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      fullWidth
      maxWidth="sm"
      style={{ zIndex: 9999 }}
    >
      <DialogTitle
        id="alert-dialog-slide-title"
        style={{
          padding: '15px 24px',
          color: '#FF9600',
          backgroundColor: '#efefef'
        }}
      >
        Fale Conosco
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            fontSize: "0.8rem",
            color: "#777",
          }}
          id="alert-dialog-slide-description"
        >
          <p style={{ marginTop: 8, alignSelf: 'center', fontSize: "0.85rem" }}>
            Segue o nosso e-mail para solicitações e esclarecimentos de dúvidas.
          </p>
          <span style={{ color: '#00BCF2', fontWeight: 600, fontSize: "0.9rem" }}>
            suportebi@schumann.com.br
          </span>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button
          style={{ textTransform: 'none', color: '#333' }}
          onClick={handleClose}
          color="inherit"
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default memo(SupportModal);
