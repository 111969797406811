import React, { useEffect } from "react";

import Breadcrumbs from "../../../../components/Breadcrumbs";

import {
  useBreadcrumbs,
  BreadcrumbProps,
} from "../../../../hooks/BreadcrumbsContext";
import { dashboardRoute } from "../../../../routes/config";

import { Container } from "./styles";

interface BaseLayoutProps {
  breadcrumbs?: BreadcrumbProps[];
}

const BaseLayout: React.FC<BaseLayoutProps> = ({
  breadcrumbs = [],
  children,
}) => {
  const { setBreadcrumbs } = useBreadcrumbs();

  useEffect(() => {
    setBreadcrumbs([
      { path: dashboardRoute.path, text: "Início" },
      ...breadcrumbs,
    ]);
  }, [setBreadcrumbs, breadcrumbs]);

  return (
    <Container>
      <Breadcrumbs />

      {children}
    </Container>
  );
};

export default BaseLayout;
