import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px 40px 30px;

  .title {
    .subtitle {
      display: inline-block;
      padding: 8px 0 24px 0;
    }
  }

  img {
    width: 100%;
    height: 600px;
  }

  .available-dashboards,
  .search-reports,
  .save-favorite-report,
  .last-accesses {
    span {
      display: inline-block;
      margin-top: 10px;
    }
  }

  .save-favorite-report {
    span:nth-of-type(1) {
      margin-bottom: 16px;
    }
  }
`;

export const Link = styled(RouterLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${props => props.theme.colors.text};
  font-size: 1rem;
  margin: 6px 0;
  transition: color 100ms ease-in-out;

  span {
    margin-left: 8px;
  }

  &:hover {
    text-decoration: underline;
  }
`
