import React, { useRef } from "react";
import { FormHandles } from "@unform/core";

import LayoutShow from "../../components/Layouts/Show";
import Form from "./components/Form";

import {
  createTaskRoute,
  listTasksRoute,
} from "../../routes/config";

export const Show: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  return (
    <LayoutShow
      breadcrumbs={[
        { text: "Tarefas", path: listTasksRoute.path },
        { text: "Visualizar Tarefa" },
      ]}
      navMenuProps={{
        newRoute: createTaskRoute.path,
      }}
    >
      <Form formRef={formRef} isShowPage />
    </LayoutShow>
  );
};
