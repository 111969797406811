import React from "react";

import { Container } from "./styles";

interface ILinearProgressProps {
  loading: boolean;
}

const LinearProgress: React.FC<ILinearProgressProps> = ({ loading }) => {
  return (
    <Container>
      <div className="card">
        <div
          className={`${loading ? "gradient" : "gradient-hide"} element`}
        ></div>
      </div>
    </Container>
  );
};

export default LinearProgress;
