import React from "react";
import { Switch } from "react-router-dom";

import Route from "../../services/route";
import RouteWrapper from "../RouteWrapper";
import { Create, List, Show } from "../../pages/Tasks";

export const createTaskRoute = new Route("/tasks/new");

export const listTasksRoute = new Route("/tasks");

export const showTaskRoute = new Route("/tasks/:id/show");

export const TasksRouteComponent: React.FC = () => (
  <Switch>
    <RouteWrapper
      exact
      isPrivate
      path={createTaskRoute.path}
      component={Create}
    />
    <RouteWrapper exact isPrivate path={listTasksRoute.path} component={List} />
    <RouteWrapper exact isPrivate path={showTaskRoute.path} component={Show} />

    <RouteWrapper
      isPrivate
      redirectIfNotExact
      path={listTasksRoute.path}
      component={List}
    />
  </Switch>
);
