import React from "react";
import { Switch } from "react-router-dom";

import Route from "../../services/route";
import RouteWrapper from "../RouteWrapper";
import {
  List,
  AvailableDashboards,
  LastAccesses,
  SaveFavoriteReport,
  SearchReports
} from "../../pages/Help";

export const helpRoute = new Route("/help");

export const availableDashboardsRoute = new Route("/help/available-dashboards");

export const lastAccessesRoute = new Route("/help/last-accesses");

export const saveFavoriteReportRoute = new Route("/help/save-favorite-report");

export const searchReportsRoute = new Route("/help/search-reports");

export const HelpRouteComponent: React.FC = () => (
  <Switch>
    <RouteWrapper
      exact
      isPrivate
      path={availableDashboardsRoute.path}
      component={AvailableDashboards}
    />
    <RouteWrapper
      exact
      isPrivate
      path={lastAccessesRoute.path}
      component={LastAccesses}
    />
    <RouteWrapper
      exact
      isPrivate
      path={helpRoute.path}
      component={List}
    />
    <RouteWrapper
      exact
      isPrivate
      path={saveFavoriteReportRoute.path}
      component={SaveFavoriteReport}
    />
    <RouteWrapper
      exact
      isPrivate
      path={searchReportsRoute.path}
      component={SearchReports}
    />

    <RouteWrapper
      isPrivate
      redirectIfNotExact
      path={helpRoute.path}
      component={List}
    />
  </Switch>
);
