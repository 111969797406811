import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { FormHandles } from "@unform/core";
import { toast } from "react-toastify";

import Form from "../../../../components/Form";
import InputText from "../../../../components/Input/Text";
import InputSelect from "../../../../components/Input/Select";

import Request from "../../../../services/request";
import {
  profilesRouteApi,
  modulesRouteApi,
  profileModulesRouteApi,
} from "../../../../routes/config/api";
import dynamicSort from "../../../../utils/dynamicSort";

interface FormProps {
  isShowPage?: boolean;
  formRef: React.RefObject<FormHandles>;
  onSubmit?: (data: any) => void;
}

const FormComponent: React.FC<FormProps> = ({
  formRef,
  isShowPage = false,
}) => {
  const [loading, setLoading] = useState(false);
  const [modules, setModules] = useState([]);
  const { id } = useParams<any>();

  useEffect(() => {
    async function findInitialData(): Promise<void> {
      try {
        setLoading(true);

        if (id) {
          const profile = await Request.get(`${profilesRouteApi.path}/${id}`);
          if (!!profile) {
            formRef.current?.setData(profile);
          }

          const profileModulesData = await Request.get(
            `${profileModulesRouteApi.path}/${id}`
          );
          if (!!profileModulesData) {
            const formattedProfileModules = profileModulesData.map((profileModule: any) => ({
              label: profileModule.module.name,
              value: profileModule.module.id,
            }));
            formRef.current?.setFieldValue('modules', formattedProfileModules);
          }
        }

        if (!isShowPage) {
          const modulesData = await Request.get(
            `${modulesRouteApi.path}?per_page=1000`
          );
          if (!!modulesData.data) {
            const formattedModules = modulesData.data.map((module: any) => ({
              label: module.name,
              value: module.id,
            }));
            setModules(formattedModules.sort(dynamicSort('label', 'ASC')));
          }
        }
      } catch (err) {
        toast.error("Ops, não foi possível carregar as informações");
      } finally {
        setLoading(false);
      }
    }

    findInitialData();
  }, [formRef, isShowPage, id]);

  return (
    <>
      <Form formRef={formRef} loading={loading} disabledForm={isShowPage}>
        <InputText
          name="name"
          label="Nome"
          helperText={isShowPage ? "" : "Campo Obrigatório"}
          autoComplete="off"
        />
        <InputSelect
          name="modules"
          label="Selecione os relatórios"
          options={modules}
          isMulti
          closeMenuOnSelect={false}
        />
      </Form>
    </>
  );
};

export default FormComponent;
