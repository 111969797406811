import React, { useRef } from "react";
import { FormHandles } from "@unform/core";
import { useParams } from "react-router-dom";

import LayoutShow from "../../components/Layouts/Show";
import Form from "./components/Form";

import {
  createModuleRoute,
  editModuleRoute,
  listModulesRoute,
} from "../../routes/config";

export const Show: React.FC = () => {
  const { id } = useParams<any>();
  const formRef = useRef<FormHandles>(null);

  return (
    <LayoutShow
      breadcrumbs={[
        { text: "Módulos", path: listModulesRoute.path },
        { text: "Visualizar Módulo" },
      ]}
      navMenuProps={{
        newRoute: createModuleRoute.path,
        editRoute: editModuleRoute.build({ id }),
      }}
    >
      <Form formRef={formRef} isShowPage />
    </LayoutShow>
  );
};
