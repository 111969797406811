import styled from "styled-components";
import { shade } from "polished";

export const Container = styled.div`
  width: 100%;
  padding: 0px;

  .powerbi-report-embed {
    height: 100%;
    border: none;

    iframe {
      border: none;
    }
  }
`;

export const FAB = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 24px;
  right: 24px;
  background-color: ${(props) => props.theme.colors.primary};
  border-radius: 50%;
  border: none;
  color: #fff;
  transition: background-color 200ms ease-in-out;

  transform: scale(1);
  animation: pulse 1300ms infinite;
  z-index: 9999;

  @keyframes pulse {
    0% {
      transform: scale(0.92);
    }

    70% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.92);
    }
  }

  ${(props) =>
    !props.disabled &&
    `
    cursor: pointer;

    &:hover {
      background-color: ${shade(0.2, props.theme.colors.primary)};
    }
  `}

  ${(props) =>
    props.disabled &&
    `
      animation: none;
      background-color: #cccccc;
  `}

  @media (max-width: 800px) {
    bottom: 20px;
    right: 20px;
  }

  @media (max-width: 600px) {
    bottom: 16px;
    right: 16px;
  }
`;

export const Error = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  h3 {
    margin-top: -20px;
    font-size: 1rem;
  }

  span {
    font-size: 0.75rem;
    margin-top: 8px;
  }

  button {
    border: none;
    margin-top: 32px;
    background-color: transparent;
    font-weight: 600;
    color: ${(props) => props.theme.colors.primary};
    font-size: 0.8rem;
    cursor: pointer;
    padding: 10px 20px;
    transition: background-color 200ms ease-in-out;

    &:hover {
      background-color: #f2f2f2;
    }
  }
`;
