import React, { useMemo } from "react";
import { AiOutlineLink } from "react-icons/ai";

import BaseLayout from "./components/BaseLayout";

import {
  availableDashboardsRoute,
  lastAccessesRoute,
  saveFavoriteReportRoute,
  searchReportsRoute,
} from "../../routes/config"

import { Container, Link } from "./styles";

const List: React.FC = () => {
  const helpList = useMemo(() => [
    {
      text: "Página Inicial - Dashboards Disponíveis",
      link: availableDashboardsRoute.path,
    },
    {
      text: "Como Pesquisar Dashboards",
      link: searchReportsRoute.path,
    },
    {
      text: "Como Salvar Dashboards nos Favoritos",
      link: saveFavoriteReportRoute.path,
    },
    {
      text: "Timeline dos Últimos Acessos",
      link: lastAccessesRoute.path,
    },
  ], []);

  return (
    <BaseLayout breadcrumbs={[{ text: "Ajuda" }]}>
      <Container>
        <div className="title">
          <h3>
            Olá, que bom te ver nesta seção de Ajuda!
            {' '} <span aria-label="Emoji sorrindo" role="img">🙂</span>
          </h3>
          <span className="subtitle">
            Aqui, você encontrará dicas de navegação no Portal BI.
          </span>
        </div>

        {helpList.map((item) => (
          <Link key={item.link} to={item.link}>
            <AiOutlineLink />
            <span>{item.text}</span>
          </Link>
        ))}
      </Container>
    </BaseLayout>
  );
};

export { List };
